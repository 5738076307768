import { useContext, useState } from "react";
import Logo from "./oss.png";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { WorkspaceContext } from "../../providers/workspace";
import {
  faArrowTurnUp,
  faBolt,
  faSearch,
  faCalendar
} from "@fortawesome/free-solid-svg-icons";
import ExposedCamerasIcon from "../../const/search/imgs/ExposedCamerasIcon";
import TrafficIcon from "../../const/search/imgs/TrafficIcon";
import SurveillanceIcon from "../../const/search/imgs/SurveillanceIcon";
import WorldcamIcon from "../../const/search/imgs/WorldcamIcon";
import WindyIcon from "../../const/search/imgs/WindyIcon";
import WikilocIcon from "../../const/search/imgs/WikilocIcon";
import OffendersIcon from "../../const/search/imgs/OffendersIcon";
import TicketmasterIcon from "../../const/search/imgs/TicketmasterIcon";
import ZillowIcon from "../../const/search/imgs/ZillowIcon";
import EventbriteIcon from "../../const/search/imgs/EventbriteIcon";
import MeetupIcon from "../../const/search/imgs/MeetupIcon";
import DiveboardIcon from "../../const/search/imgs/DiveboardIcon";
import OutdooractiveIcon from "../../const/search/imgs/OutdooractiveIcon";
import FindpenguinsIcon from "../../const/search/imgs/FindpenguinsIcon";
import AcledIcon from "../../const/search/imgs/AcledIcon";
import Events3Icon from "../../const/search/imgs/Events3Icon";
import {
  faBullhorn,
  faImages,
  faTimeline,
} from "@fortawesome/free-solid-svg-icons";
import Events4Component from "../../const/search/imgs/Events4Icon";
import Events5Component from "../../const/search/imgs/Events5Icon";
import BookingComponent from "../../const/search/imgs/BookingIcon";

import { faBicycle } from "@fortawesome/free-solid-svg-icons";
import { ThemeContext } from "@/providers/theme";
import { Switch } from "../ui/switch";

export const Sidebar = (props) => {
  const [isStatic, setIsStatic] = useState(false);
  const { isDark, changeTheme } = useContext(ThemeContext);
  const { workspace } = useContext(WorkspaceContext);

  const managementId = process.env.REACT_APP_AUTH_MANAGEMENT_ID;
  const isManagementAuthenticated = props.user.sub === managementId;

  const menuItems = [
    workspace
      ? {
          title: "",
          submenu: [
            {
              icon: ["fa-solid", "fa-home"],
              url: "/dashboard",
              title: "Dashboard",
            },
            {
              icon: icon({ name: "map", style: "solid" }),
              url: "/search",
              title: "Map",
            },
            {
              icon: faTimeline,
              url: "/timeline",
              title: "Timeline",
            },
          ],
        }
      : {},
    {
      title: "Locations",
      submenu: [
        {
          icon: ["fa-solid", "fa-location-crosshairs"],
          url: "/add-location",
          title: "Add Location",
        },
        {
          title: "My Locations",
          url: "/database",
          icon: ["fa-solid", "fa-database"],
        },
        {
          title: "Search in database",
          url: "/search-database",
          icon: faSearch,
        },
        {
          title: "Search username",
          url: "/search-username",
          icon: icon({ name: "person", style: "solid" }),
        },
        {
          title: "Geolocate photo",
          url: "/geolocate-photo",
          icon: faImages,
        },
        {
          title: "Upload CSV",
          url: "/upload-locations",
          icon: icon({ name: "file-import", style: "solid" }),
        },
      ],
    },
    workspace
      ? {
          title: "Social networks",
          submenu: [
            {
              icon: ["fab", "airbnb"],
              url: "/module/airbnb",
              title: "Airbnb",
            },
            {
              icon: icon({
                name: "mountain",
                family: "classic",
                style: "solid",
              }),
              url: "/module/alltrails",
              title: "Alltrails",
            },
            {
              icon: faBicycle,
              url: "/module/bikemap",
              title: "Bikemap",
            },
            {
              icon: BookingComponent,
              url: "/module/booking",
              title: "Booking",
            },
            {
              icon: DiveboardIcon,
              url: "/module/diveboard",
              title: "Diveboard",
            },
            {
              icon: FindpenguinsIcon,
              url: "/module/findpenguins",
              title: "Findpenguins",
            },
            {
              icon: ["fab", "facebook"],
              url: "/module/facebook",
              title: "Facebook (#)",
            },
            {
              icon: ["fab", "flickr"],
              url: "/module/flickr",
              title: "Flickr",
            },
            {
              icon: ["fab", "instagram"],
              url: "/module/instagram",
              title: "Instagram (#)",
            },
            {
              icon: OutdooractiveIcon,
              url: "/module/outdooractive",
              title: "Outdooractive",
            },
            {
              icon: ["fab", "snapchat"],
              url: "/module/snapchat",
              title: "Snapchat",
            },
            {
              icon: icon({
                name: "person-running",
                family: "classic",
                style: "solid",
              }),
              url: "/module/sports_tracker",
              title: "SportsTracker",
            },
            // {
            //   icon: ["fab", "strava"],
            //   url: "/module/strava",
            //   title: "Strava",
            // },
//             {
//               icon: ["fab", "telegram"],
//               url: "/module/telegram",
//               title: "Telegram* (Premium)",
//             },
            {
              icon: ["fab", "twitter"],
              url: "/module/twitter",
              title: "Twitter",
            },

            {
              url: "/module/tiktok",
              title: "Tiktok (#)",
              icon: ["fab", "tiktok"],
            },
            {
              icon: ["fab", "vk"],
              url: "/module/vkontakte",
              title: "Vkontakte",
            },
            {
              icon: ["fab", "weibo"],
              url: "/module/weibo",
              title: "Weibo",
            },
            {
              icon: WikilocIcon,
              url: "/module/wikiloc",
              title: "Wikiloc",
            },
            {
              icon: ["fab", "youtube"],
              url: "/module/youtube",
              title: "Youtube",
            },
          ],
        }
      : {},
    workspace
      ? {
          title: "Public cameras",
          submenu: [
            {
              //    'icon': ['fab', 'camera-cctv'],
              icon: SurveillanceIcon,
              url: "/module/surveillance",
              title: "Surveillance",
            },
            {
              icon: TrafficIcon,
              url: "/module/traffic",
              title: "Traffic",
            },
            {
              icon: WindyIcon,
              url: "/module/windy",
              title: "Windy",
            },
            {
              icon: WorldcamIcon,
              url: "/module/worldcam",
              title: "Worldcam",
            },
          ],
        }
      : {},
    workspace
      ? {
          title: "Internet facing devices",
          submenu: [
            {
              icon: ExposedCamerasIcon,
              url: "/module/exposed_cameras",
              title: "Cameras",
            },
            {
              icon: icon({ name: "building-lock", style: "solid" }),
              url: "/module/ics",
              title: "ICS",
            },
            {
              icon: icon({ name: "house-signal", style: "solid" }),
              url: "/module/iot",
              title: "Internet of Things",
            },
            {
              icon: icon({ name: "image", style: "solid" }),
              url: "/module/screenshots",
              title: "Screenshots",
            },
            {
              icon: icon({ name: "dumpster-fire", style: "solid" }),
              url: "/module/vulnerabilities",
              title: "Vulnerabilities",
            },
          ],
        }
      : {},

    workspace
      ? {
          title: "Networks",
          submenu: [
            {
              icon: ["fab", "bluetooth"],
              url: "/module/bluetooth",
              title: "Bluetooth",
            },
            {
              icon: icon({ name: "tower-cell", style: "solid" }),
              url: "/module/cell_tower",
              title: "Cell Towers",
            },
            {
              icon: icon({ name: "wifi", style: "solid" }),
              url: "/module/wifi",
              title: "Wifi",
            },
          ],
        }
      : {},
    workspace
      ? {
          title: "Events",
          submenu: [
            {
              icon: ["fab", "airbnb"],
              url: "/module/experiences",
              title: "Airbnb Experiences",
            },
            {
              icon: icon({ name: "child", style: "solid" }),
              url: "/module/amber_alerts",
              title: "Amber Alerts",
            },
            {
              icon: icon({ name: "peace", style: "solid" }),
              url: "/module/craigslist",
              title: "Craigslist",
            },
            {
              icon: icon({ name: "handcuffs", style: "solid" }),
              url: "/module/crimes",
              title: "Crimes",
            },
            {
              icon: icon({ name: "people-robbery", style: "solid" }),
              url: "/module/crimes2",
              title: "Crimes 2",
            },
            {
              icon: EventbriteIcon,
              url: "/module/eventbrite",
              title: "Eventbrite",
            },
            {
              icon: faBolt,
              url: "/module/events1",
              title: "Events 1",
            },
            {
              icon: faBullhorn,
              url: "/module/events2",
              title: "Events 2",
            },
            {
              icon: Events3Icon,
              url: "/module/events3",
              title: "Events 3",
            },
            {
              icon: Events4Component,
              url: "/module/events4",
              title: "Events 4",
            },
            {
              icon: Events5Component,
              url: "/module/events5",
              title: "Events 5",
            },
            //             {
            //               icon: MeetupIcon,
            //               url: "/module/meetup",
            //               title: "Meetup",
            //             },
                        {
              icon: icon({ name: "shop", style: "solid" }),
              url: "/module/facebook_marketplace",
              title: "FB Marketplace",
            },
            {
              icon: faCalendar,
              url: "/module/facebook_events",
              title: "Facebook Events",
            },
            {
              icon: icon({ name: "newspaper", style: "solid" }),
              url: "/module/news",
              title: "News",
            },
            // {
            //   icon: NextdoorIcon,
            //   url: "/module/nextdoor",
            //   title: "Nextdoor",
            // },
            {
              icon: AcledIcon,
              url: "/module/protests",
              title: "Protests",
            },
            {
              icon: OffendersIcon,
              url: "/module/offenders",
              title: "Offenders",
            },
            {
              icon: TicketmasterIcon,
              url: "/module/ticketmaster",
              title: "Ticketmaster",
            },
            {
              icon: ZillowIcon,
              url: "/module/zillow",
              title: "Zillow",
            },
          ],
        }
      : {},
    workspace
      ? {
          title: "Transport",
          submenu: [
              {
              icon: icon({ name: "plane-arrival", style: "solid" }),
              url: "/module/airports",
              title: "Airports",
            },
            {
              icon: icon({ name: "train", style: "solid" }),
              url: "/module/amtrak",
              title: "Amtrak",
            },
            {
              icon: icon({ name: "triangle-exclamation", style: "solid" }),
              url: "/module/incidents",
              title: "Incidents",
            },
            {
              icon: icon({ name: "plane", style: "solid" }),
              url: "/module/planes",
              title: "Planes",
            },
            {
              icon: icon({ name: "ship", style: "solid" }),
              url: "/module/vessel",
              title: "Vessels",
            },
            {
              icon: ["fa-brands", "fa-waze"],
              url: "/module/waze",
              title: "Waze",
            },
          ],
        }
      : {},
    workspace
      ? {
          title: "Critical infrastructure",
          submenu: [
            {
              icon: icon({ name: "tower-broadcast", style: "solid" }),
              url: "/module/communication",
              title: "Communication",
            },
            {
              icon: icon({ name: "truck-medical", style: "solid" }),
              url: "/module/emergency",
              title: "Emergency",
            },
            {
              icon: icon({ name: "person-military-rifle", style: "solid" }),
              url: "/module/military",
              title: "Military",
            },
            {
              icon: icon({ name: "bolt", style: "solid" }),
              url: "/module/power",
              title: "Power",
            },
            {
              icon: icon({ name: "place-of-worship", style: "solid" }),
              url: "/module/sacral",
              title: "Worship",
            },
            {
              icon: icon({ name: "truck", style: "solid" }),
              url: "/module/transportation",
              title: "Transportation",
            },
            {
              icon: icon({ name: "droplet", style: "solid" }),
              url: "/module/water",
              title: "Water",
            },
          ],
        }
      : {},
    workspace
      ? {
          title: "Other",
          submenu: [
            {
              icon: icon({ name: "house-user", style: "solid" }),
              url: "/module/trestle",
              title: "Address lookup",
            },
            {
              icon: icon({ name: "satellite", style: "solid" }),
              url: "/module/ai_satellite",
              title: "AI Satellite",
            },
            {
              icon: icon({ name: "house-flood-water", style: "solid" }),
              url: "/module/disasters",
              title: "Disasters",
            },
            {
              icon: icon({ name: "futbol", style: "solid" }),
              url: "/module/stadium",
              title: "Stadiums",
            },
            {
              icon: icon({ name: "store", style: "solid" }),
              url: "/module/stores",
              title: "Stores",
            },
          ],
        }
      : {},
  ];

  return (
    <nav
      onMouseEnter={() => !isStatic && props.toggleSidebar(true)}
      onMouseLeave={() => !isStatic && props.toggleSidebar(false)}
      className={`${
        isStatic ? "sidebar" : props.shown ? "sidebar" : "sidebar close"
      } z-10`}
    >
      <header>
        <div className="image-text pb-2 justify-between">
          <div className="flex items-center gap-1">
            <span className="image">
              <img src={Logo} alt="" />
            </span>
            <div className="text text-[var(--text-color)]">
              <span className="profession !text-base">Open Source</span>
              <span className="profession !text-base">Surveillance</span>
            </div>
          </div>
          <button
            className={`transition-transform ${
              isStatic ? "rotate-90" : "rotate-0"
            }`}
            onClick={() => setIsStatic((prev) => !prev)}
          >
            <FontAwesomeIcon className="icon" icon={faArrowTurnUp} />
          </button>
        </div>
        {!props.shown ? (
          <FontAwesomeIcon
            className="toggle text-[var(--text-color)]"
            height={48}
            width={48}
            icon="fa-bars"
            onClick={props.toggleSidebar}
          />
        ) : (
          <FontAwesomeIcon
            className="toggle text-[var(--text-color)]"
            height={48}
            width={48}
            icon="fa-chevron-left"
            onClick={props.toggleSidebar}
          />
        )}
      </header>
      <div className="menu-bar">
        <div className="menu">
          <ul className="flex flex-col gap-4">
            {menuItems.map((item, key) => (
              <li className="nav-group" key={key}>
                <ul>
                  <p className="w-[2in]">{item.title}</p>
                  {item.submenu &&
                    item.submenu.map((subitem, id) => {
                      const Icon = subitem.icon;
                      return (
                        <li key={id}>
                          <NavLink className="mr-4" to={subitem.url}>
                            {({ isActive }) => (
                              <div className="flex items-center group w-full">
                                {typeof subitem.icon === "function" ? (
                                  <div className="min-w-[60px] stroke-[var(--text-color)] fill-[var(--text-color)] grid place-content-center w-4 h-4">
                                    <div
                                      className={`h-8 w-8 grid place-content-center transition-colors ${
                                        isActive
                                          ? "bg-[var(--primary-color)]"
                                          : "group-hover:bg-[var(--primary-color)]"
                                      } px-2 rounded`}
                                    >
                                      <Icon />
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className={`text-[var(--text-color)] min-w-[60px] grid place-content-center`}
                                  >
                                    <div
                                      className={`h-8 w-8 grid place-content-center transition-colors ${
                                        isActive
                                          ? "bg-[var(--primary-color)]"
                                          : "group-hover:bg-[var(--primary-color)]"
                                      } ${
                                        isStatic || props.shown
                                          ? "px-2"
                                          : "px-0"
                                      } rounded`}
                                    >
                                      <FontAwesomeIcon
                                        className="bx bx-home-alt"
                                        icon={subitem.icon}
                                      />
                                    </div>
                                  </div>
                                )}
                                <div
                                  className={`h-8 flex-1 items-center -ml-4 px-2 rounded-r transition-colors ${
                                    isActive
                                      ? "bg-[var(--primary-color)]"
                                      : "group-hover:bg-[var(--primary-color)]"
                                  } ${
                                    isStatic || props.shown ? "flex" : "hidden"
                                  }`}
                                >
                                  <span className="text-[var(--text-color)]">
                                    {subitem.title}
                                  </span>
                                </div>
                              </div>
                            )}
                          </NavLink>
                        </li>
                      );
                    })}
                </ul>
              </li>
            ))}
          </ul>
        </div>
        <div className="bottom-content mt-8">
          <p
            className={`${
              props.shown || isStatic ? "opacity-100" : "opacity-0"
            } text-[var(--primary-color)] m-[1.4rem_0_0_1.4rem]  transition-opacity uppercase tracking-[0.2rem] font-semibold text-[.8rem]`}
          >
            SETTINGS
          </p>
          <li>
            <NavLink to="/settings">
              {({ isActive }) => (
                <div className="flex items-center group w-full">
                  <div
                    className={`text-[var(--text-color)] min-w-[60px] grid place-content-center`}
                  >
                    <div
                      className={`h-8 w-8 grid place-content-center transition-colors ${
                        isActive
                          ? "bg-[var(--primary-color)]"
                          : "group-hover:bg-[var(--primary-color)]"
                      } ${isStatic || props.shown ? "px-2" : "px-0"} rounded`}
                    >
                      <FontAwesomeIcon icon={icon({ name: "gear" })} />
                    </div>
                  </div>
                  <div
                    className={`h-8 flex-1 items-center -ml-4 px-2 rounded-r transition-colors ${
                      isActive
                        ? "bg-[var(--primary-color)]"
                        : "group-hover:bg-[var(--primary-color)]"
                    } ${isStatic || props.shown ? "flex" : "hidden"}`}
                  >
                    <span className="text-[var(--text-color)]">Settings</span>
                  </div>
                </div>
              )}
            </NavLink>
          </li>
          {isManagementAuthenticated && (
            <li>
              <NavLink to="/reservoir">
                {({ isActive }) => (
                  <div className="flex items-center group w-full">
                    <div
                      className={`text-[var(--text-color)] min-w-[60px] grid place-content-center`}
                    >
                      <div
                        className={`h-8 w-8 grid place-content-center transition-colors ${
                          isActive
                            ? "bg-[var(--primary-color)]"
                            : "group-hover:bg-[var(--primary-color)]"
                        } ${isStatic || props.shown ? "px-2" : "px-0"} rounded`}
                      >
                        <FontAwesomeIcon icon={icon({ name: "glass-water" })} />
                      </div>
                    </div>
                    <div
                      className={`h-8 flex-1 items-center -ml-4 px-2 rounded-r transition-colors ${
                        isActive
                          ? "bg-[var(--primary-color)]"
                          : "group-hover:bg-[var(--primary-color)]"
                      } ${isStatic || props.shown ? "flex" : "hidden"}`}
                    >
                      <span className="text-[var(--text-color)]">
                        Reservoir
                      </span>
                    </div>
                  </div>
                )}
              </NavLink>
            </li>
          )}
          <li>
            <NavLink to="/">
              {({ isActive }) => (
                <div className="flex items-center group w-full">
                  <div
                    className={`text-[var(--text-color)] min-w-[60px] grid place-content-center`}
                  >
                    <div
                      className={`h-8 w-8 grid place-content-center transition-colors ${
                        isActive
                          ? "bg-[var(--primary-color)]"
                          : "group-hover:bg-[var(--primary-color)]"
                      } ${isStatic || props.shown ? "px-2" : "px-0"} rounded`}
                    >
                      <FontAwesomeIcon icon={icon({ name: "money-bill" })} />
                    </div>
                  </div>
                  <div
                    className={`h-8 flex-1 items-center -ml-4 px-2 rounded-r transition-colors ${
                      isActive
                        ? "bg-[var(--primary-color)]"
                        : "group-hover:bg-[var(--primary-color)]"
                    } ${isStatic || props.shown ? "flex" : "hidden"}`}
                  >
                    <span className="text-[var(--text-color)]">
                      Subscription
                    </span>
                  </div>
                </div>
              )}
            </NavLink>
          </li>
          <li>
            <button
              className="flex items-center"
              onClick={() =>
                props.logout({
                  logoutParams: { returnTo: window.location.origin },
                })
              }
            >
              <FontAwesomeIcon className="icon" icon="fa-power-off" />
              <span className="text-[var(--text-color)] text-[14px]">
                Logout
              </span>
            </button>
          </li>

          <li className="mode">
            <div className="sun-moon">
              {isDark ? (
                <FontAwesomeIcon className="icon" icon="fa-regular fa-moon" />
              ) : (
                <FontAwesomeIcon className="icon" icon="fa-regular fa-sun" />
              )}
            </div>
            <span className="text-[var(--text-color)] text-[14px]">
              Dark mode
            </span>
            {/* <div className="toggle-switch" onClick={changeTheme}>
              <span className="switch"></span>
            </div> */}
            <div className="flex-1 flex justify-end">
              <Switch checked={isDark} onCheckedChange={changeTheme} />
            </div>
          </li>
        </div>
      </div>
    </nav>
  );
};
