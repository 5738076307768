import { Components } from "react-markdown";

const markdownComponents: Partial<Components> = {
  h2: ({ children, ...props }) => (
    <h2
      {...props}
      className="text-2xl bg-pink dark:bg-blue text-[#F5F5F5] dark:text-[#252525] rounded-md py-2 px-4 max-w-max font-medium mt-2 mb-1"
    >
      {children}
    </h2>
  ),
  h3: ({ children, ...props }) => (
    <h3
      {...props}
      className="text-xl bg-pink dark:bg-blue text-[#F5F5F5] dark:text-[#252525] rounded-md py-2 px-4 max-w-max font-medium mt-2 mb-1"
    >
      {children}
    </h3>
  ),
  h4: ({ children, ...props }) => (
    <h4
      {...props}
      className="text-lg bg-pink dark:bg-blue text-[#F5F5F5] dark:text-[#252525] rounded-md py-2 px-4 max-w-max font-medium mt-2 mb-1"
    >
      {children}
    </h4>
  ),
  p: ({ children, ...props }) => (
    <p
      {...props}
      className="text-base text-[#252525] dark:text-[#F5F5F5] leading-relaxed"
    >
      {children}
    </p>
  ),
  ul: ({ children, ...props }) => (
    <ul {...props} className="grid gap-1">
      {children}
    </ul>
  ),
  ol: ({ children, ...props }) => (
    <ol {...props} className="grid gap-1">
      {children}
    </ol>
  ),
  li: ({ children, ...props }) => (
    <li
      {...props}
      className="text-base text-[#252525] dark:text-[#F5F5F5] leading-relaxed"
    >
      {children}
    </li>
  ),
};

export default markdownComponents;
