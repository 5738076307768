import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MarkerF, InfoWindowF } from "@react-google-maps/api";
import Pin2 from "./imgs/pin2.svg";
import { useAuth0 } from "@auth0/auth0-react";
import Slider from "react-input-slider";
import Button from "../Button";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { TutorialContext } from "../../providers/tutorial";
import StepPopup from "../tutorial/step";

export default function CustomMarker({ tutorialVisible, ...props }) {
  const { changeStep } = useContext(TutorialContext);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const { position, clusterer, id } = props;
  const [, setInput] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [activeMarker, setActiveMarker] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
  useEffect(() => {
    setActiveMarker(1);
  }, []);

  const onInputChange = (value) => {
    setInputValue(value);
  };

  const handleButtonClick = async (position) => {
    setIsLoading(true);
    const data = {
      position: position,
      radius: props.radius,
      title: props && props.locationTitle ? props.locationTitle : inputValue,
    };
    const token = await getAccessTokenSilently();
    const url = `${process.env.REACT_APP_API_SERVER_URL}/api/addCoordinates/`;
    try {
      await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.error("There was an error!", error);
    } finally {
      setIsLoading(false);
    }
    navigate("/database");
  };

  return (
    <MarkerF
      position={position}
      clusterer={clusterer}
      onClick={() => handleActiveMarker(id)}
      icon={{
        // path: google.maps.SymbolPath.CIRCLE,
        url: Pin2,
      }}
    >
      {activeMarker === id ? (
        <InfoWindowF
          // className="z-10"
          onCloseClick={() => !isLoading && setActiveMarker(null)}
          // use this aria-label when there's no default info window title (custom one is being rendered)
          // it prevents the 'x' button from being rendered on top of the custom title
          options={{ ariaLabel: "custom-window-marker" }}
        >
          <form
            className="map-popup-body min-w-[3in] relative z-20"
            onSubmit={(e) => {
              e.preventDefault();
              tutorialVisible && changeStep();
              handleButtonClick(props.position);
            }}
          >
            <span
              className={`relative z-20 max-w-[14rem] ${
                props && props.locationTitle ? "title overformed" : "title"
              } !text-base`}
            >
              {props && props.locationTitle
                ? props.locationTitle
                : `Please, provide location name`}
            </span>
            {props && !props.locationTitle ? (
              <input
                placeholder="Provide location..."
                value={inputValue}
                ref={(input) => setInput(input)}
                onChange={(event) => onInputChange(event.target.value)}
                className="keyword_search !rounded-sm text-[var(--text-color)] mt-2 mb-4 relative z-20"
                type="text"
                required
              ></input>
            ) : null}
            <span className="title !text-base relative z-20">
              {`Search radius`} - <b>{props.radius} m</b>
            </span>
            <div className="w-full mb-4 relative z-20">
              <Slider
                axis="x"
                x={props.radius}
                xstep={1000}
                xmin={1000}
                xmax={1000000}
                styles={{
                  track: {
                    width: "100%",
                  },
                }}
                onChange={({ x }) => props.setRadius(x)}
              />
            </div>
            <Button
              className="relative z-20"
              disabled={isLoading}
              icon={faPlus}
            >
              Add this area
            </Button>
          </form>
        </InfoWindowF>
      ) : null}

      {tutorialVisible && (
        <StepPopup
          title="Defining bounds and naming"
          paragraph="Name your location and define the bounds."
          actionText="Click on the button"
          position="top-0 right-0"
        />
      )}
    </MarkerF>
  );
}
