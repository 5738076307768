import { Fragment, MouseEvent, useLayoutEffect, useRef, useState } from "react";
import { MODULES } from "../../const/search";
import AllStatsList from "./all";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import DefaultStatsList from "./default";
import Places from "./places";
import Timeline from "./ui/timeline";

type Props = {
  stats: Stats;
  count: Count;
  places: string[];
  timeline: SingleRecord[];
};

export default function StatsList({ stats, count, places, timeline }: Props) {
  const [isExpanded, setIsExpanded] = useState(true);
  const [mode, setMode] = useState<"stats" | "timeline">("stats");
  const [isScrolled, setIsScrolled] = useState({
    right: true,
    left: true,
  });
  const ref = useRef<HTMLDivElement | null>(null);
  const keys = Object.keys(stats).filter(
    (key) => Object.keys(stats[key as keyof Stats]).length > 0
  );

  function onScroll() {
    if (!ref.current) return;
    const scroll = ref.current.scrollLeft;
    setIsScrolled({
      right:
        ref.current.scrollLeft >=
        ref.current.scrollWidth - ref.current.offsetWidth,
      left: scroll === 0,
    });
  }

  function handleScroll(
    e: MouseEvent<HTMLButtonElement>,
    direction: "left" | "right"
  ) {
    e.preventDefault();
    if (!ref.current) return;
    const scroll = ref.current.scrollLeft;
    const diff = ref.current.clientWidth / 2.5;
    ref.current.scrollTo({
      left: direction === "right" ? scroll + diff : scroll - diff,
      behavior: "smooth",
    });
  }

  useLayoutEffect(() => {
    if (mode === "stats") {
      onScroll();
      window.addEventListener("resize", onScroll);
    }
    return () => {
      window.removeEventListener("resize", onScroll);
    };
  }, [mode]);

  const changeMode = (mode: "stats" | "timeline") => {
    ref.current?.scrollTo({ left: 0, behavior: "smooth" });
    setMode(mode);
  };

  return (
    <div
      className={`absolute z-30 bottom-0 left-0 right-0 flex items-center justify-center transition-transform ${
        isExpanded ? "translate-y-0" : "translate-y-full"
      }`}
    >
      <div className="absolute left-0 bottom-full flex items-center h-8 w-64 text-[var(--text-color)] text-lg">
        <button
          onClick={() => changeMode("stats")}
          className={`w-1/2 h-full group ${
            mode === "stats"
              ? "bg-[var(--body-color)]"
              : "bg-[var(--sidebar-color)]"
          }`}
        >
          <span
            className={`transition-opacity ${
              mode === "stats"
                ? "opacity-100"
                : "opacity-80 group-hover:opacity-100"
            }`}
          >
            Stats
          </span>
        </button>
        <button
          onClick={() => changeMode("timeline")}
          className={`w-1/2 h-full group ${
            mode === "timeline"
              ? "bg-[var(--body-color)]"
              : "bg-[var(--sidebar-color)]"
          }`}
        >
          <span
            className={`transition-opacity ${
              mode === "timeline"
                ? "opacity-100"
                : "opacity-80 group-hover:opacity-100"
            }`}
          >
            Timeline
          </span>
        </button>
      </div>
      {mode === "stats" ? (
        <div
          className="bg-[var(--body-color)] overflow-x-auto overflow-y-hidden hide-scroll min-w-full h-[17rem]"
          ref={ref}
          onScroll={onScroll}
        >
          <div className="p-4 flex items-stretch h-full gap-4 min-w-max">
            <AllStatsList {...count} />
            <Places places={places} />
            {keys.map((key) => {
              const obj = MODULES.find((item) => item.value === key);
              const props = stats[key as keyof Stats] as Activity & Records;
              if (!obj) {
                return <DefaultStatsList {...props} name={key} />;
              }
              const Component = obj?.stats;
              if (!Component) return <DefaultStatsList {...props} name={key} />;
              return <Component {...props} stroke={obj?.color} />;
            })}
          </div>
        </div>
      ) : (
        <Timeline timeline={timeline} />
      )}
      {mode === "stats" && (
        <Fragment>
          <button
            onClick={(e) => handleScroll(e, "right")}
            className={`absolute right-8 rounded-full bg-[var(--primary-color2)] h-12 w-12 shadow z-10 transition-opacity ${
              isScrolled.right ? "opacity-0 pointer-events-none" : "opacity-100"
            }`}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
          <button
            onClick={(e) => handleScroll(e, "left")}
            className={`absolute left-8 rounded-full bg-[var(--primary-color2)] h-12 w-12 shadow z-10 transition-opacity ${
              isScrolled.left ? "opacity-0 pointer-events-none" : "opacity-100"
            }`}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        </Fragment>
      )}
      <button
        onClick={() => setIsExpanded((prev) => !prev)}
        className={`absolute bottom-full bg-[var(--body-color)] h-4 w-32 rounded-t-lg flex flex-col items-center justify-center gap-1 transition-[padding-top] ${
          isExpanded ? "pt-2" : "pt-0"
        }`}
      >
        <div className="w-1/2 rounded-full h-px bg-slate-400 dark:bg-slate-700"></div>
        <div className="w-1/2 rounded-full h-px bg-slate-400 dark:bg-slate-700"></div>
      </button>
    </div>
  );
}
