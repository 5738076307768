// name of an array = name of type or shodan_type of module

export const SINGLE_ATTRIBUTE_EXCLUSIONS = {
  airbnb: [
    "notes",
    "coordinates",
    "url",
    "search_request",
    "id",
    "type",
    "photo_url",
    "error",
    "date_created",
    "is_favorite",
  ],
  alltrails: [
    "coordinates",
    "url",
    "search_request",
    "id",
    "type",
    "photo_url",
    "error",
    "date_created",
    "notes",
    "is_favorite",
  ],
  flickr: [
    "notes",
    "coordinates",
    "url",
    "search_request",
    "id",
    "type",
    "photo_url",
    "error",
    "date_created",
  ],
  snapchat: [
    "notes",
    "coordinates",
    "url",
    "search_request",
    "id",
    "type",
    "photo_url",
    "error",
    "date_created",
    "is_favorite",
  ],
  sports_tracker: [
    "notes",
    "coordinates",
    "url",
    "search_request",
    "id",
    "type",
    "photo_url",
    "error",
    "date_created",
    "is_favorite",
  ],
  strava: [
    "notes",
    "coordinates",
    "url",
    "search_request",
    "id",
    "type",
    "photo_url",
    "error",
  ],
  vkontakte: [
    "notes",
    "coordinates",
    "url",
    "search_request",
    "id",
    "type",
    "photo_url",
    "error",
    "date_created",
    "is_favorite",
  ],
  instagram: [
    "notes",
    "coordinates",
    "url",
    "search_request",
    "id",
    "type",
    "photo_url",
    "error",
  ],
  diveboard: [
    "notes",
    "coordinates",
    "url",
    "search_request",
    "id",
    "type",
    "photo_url",
    "is_favorite",
    "error",
    "date_created",
    "is_favorite",
  ],
  surveillance: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "description",
    "camera_type",
    "photo_url",
    "error",
    "date_created",
    "is_favorite",
  ],
  traffic: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "description",
    "camera_type",
    "photo_url",
    "error",
    "date_created",
    "is_favorite",
  ],
  worldcam: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "description",
    "camera_type",
    "photo_url",
    "url",
    "error",
    "date_created",
    "is_favorite",
  ],
  windy: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "camera_type",
    "photo_url",
    "url",
    "error",
    "date_created",
    "is_favorite",
  ],

  exposed_cameras: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "screenshot",
    "error",
  ],
  ics: ["id", "notes", "search_request", "type", "coordinates", "error"],
  iot: ["id", "notes", "search_request", "type", "coordinates", "error"],
  screenshots: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "screenshot",
    "photo_url",
    "error",
  ],
  vulnerabilities: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "screenshot",
    "error",
  ],
  amber_alerts: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "event_id",
    "event_type",
    "error",
    "url",
    "date_created",
    "is_favorite",
  ],
  crimes: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "event_id",
    "event_type",
    "url",
    "error",
    "is_favorite",
    "date_created",
  ],
  bluetooth: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "date_created",
    "error",
    "is_favorite",
  ],
  network: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "date_created",
    "error",
    "is_favorite",
  ],
  wifi: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "date_created",
    "error",
    "is_favorite",
  ],
  weibo: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "profile_url",
    "profile_image_url",
    "date_created",
    "id",
    "url",
    "error",
    "is_favorite",
    "photo_url",
  ],
  cell_tower: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "wigle_type",
    "error",
  ],
  youtube: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "id",
    "date_created",
    "photo_url",
    "url",
    "error",
  ],
  planes: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "radarbox_id",
    "squawk_code",
    "radar_id",
    "photo_url",
    "date_created",
    "is_favorite",
    "url",
    "wake",
    "radarbox_id",
    "error",
  ],
  amtrak: ["id", "notes", "search_request", "type", "coordinates"],
  vessel: [
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "wigle_type",
    "type",
    "date_created",
    "is_favorite",
    "shipxplorer_id",
    "photo_url",
    "data_source",
    "error",
  ],
  incidents: ["id", "notes", "search_request", "type", "coordinates", "error"],
  water: ["id", "notes", "search_request", "type", "coordinates", "polygon"],
  military: ["id", "notes", "search_request", "type", "coordinates", "polygon"],
  power: ["id", "notes", "search_request", "type", "coordinates", "polygon"],
  emergency: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "polygon",
    "error",
  ],
  communication: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "polygon",
  ],
  transportation: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "polygon",
  ],
  sacral: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "polygon",
    "error",
  ],
  wikiloc: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "error",
    "is_favorite",
    "photo_url",
  ],
  offenders: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "aid",
    "error",
  ],
  ticketmaster: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "error",
    "photo_url",
    "date_created",
  ],
  zillow: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "photo_url",
    "is_favorite",
    "error",
  ],
  craigslist: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "photo_url",
    "is_favorite",
    "error",
  ],
  eventbrite: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "event_id",
    "error",
  ],
  meetup: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "group_id",
    "photo_url_group",
    "error",
  ],
  nextdoor: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "timestamp",
    "error",
  ],
  experiences: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "photo_url",
    "is_favorite",
    "location",
    "error",
  ],
  outdooractive: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "photo_url",
    "is_favorite",
    "error",
  ],
  waze: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "uuid",
    "municipality_user",
    "error",
  ],
  police: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "uuid",
    "municipality_user",
    "error",
  ],
  news: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "photo_url",
    "is_favorite",
    "location",
    "logo",
    "error",
  ],
  findpenguins: [
    "id",
    "notes",
    "search_request",
    "type",
    "coordinates",
    "url",
    "photo_url",
    "is_favorite",
    "location",
    "error",
  ],
  bikemap: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
  ],
  protests: [
    "id",
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "error",
  ],

  events1: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "event_id",
    "description",
    "error",
  ],
  events2: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "event_id",
    "description",
    "details",
    "error",
  ],
  events3: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "source",
    "error",
  ],
  photo_geolocation: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "screenshot",
    "error",
  ],
  crimes2: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
  ],
  events4: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
  ],
  events5: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
  ],
  booking: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
    "page_name",
    "place_id",
    "country_code",
  ],
  tiktok: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
  ],
  twitter: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
    "tweet_id",
  ],
  facebook: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
  ],
  telegram: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
  ],
  trestle: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
    "trestle_id",
    "resident_id",
  ],
  ai_satellite: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
    "trestle_id",
    "resident_id",
  ],
  facebook_events: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
  ],
  facebook_marketplace: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
  ],
  stores: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
    "store_id",
  ],
  airports: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
  ],
  stadium: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
  ],
    disasters: [
    "search_request",
    "type",
    "coordinates",
    "date_created",
    "url",
    "photo_url",
    "notes",
    "is_favorite",
    "location",
    "type",
    "error",
    "description",
    "disaster_id",
  ],
};

export const AUTHOR_EXCLUSIONS = [
  "photo_url",
  //  "last_name",
  //  "username",
  "is_superhost",
  //  "url",
  "post_photo_url",
  "post_url",
  "associated_item",
];

export const FIELD_COL_SPAN = ["description", "comment", "caption"];
