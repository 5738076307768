import { Link } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import isImgUrl from "../../utils/isImgUrl";
import ReactHlsPlayer from "react-hls-player";
import { Card, CardContent, CardFooter } from "../ui/card";
import { cn } from "@/utils/cn";
import { ScrollArea } from "../ui/scroll-area";
import Attributes from "../ui/attributes";
import Markdown from "react-markdown";
import markdownComponents from "@/const/mdx-components";

export default function DrawerItem({ isFullHeight, item, position, ...props }) {
  const [isUrlAnImage, setIsUrlAnImage] = useState(false);

  const isM3U8 = item.photo_url && item.photo_url.endsWith(".m3u8");

  useEffect(() => {
    if (!item.photo_url || item.type === "screenshots") return;
    (async () => {
      const isImage = await isImgUrl(item.photo_url);
      isImage ? setIsUrlAnImage(true) : setIsUrlAnImage(false);
    })();
  }, [item.photo_url, item.type]);

  const title = item.title || item.name;

  const description = item.description || item.text || item.summary;

  return (
    <Card
      {...props}
      className={cn("group w-full gap-6", isFullHeight ? "h-full" : "h-auto")}
    >
      {item.photo_url &&
        (isUrlAnImage ? (
          <img
            className="w-full object-contain max-h-[4in]"
            src={item.photo_url}
            alt=""
          />
        ) : (
          <div className="flex items-center justify-center h-[4in]">
            {isM3U8 ? (
              <HslPlayer {...item} />
            ) : (
              <video
                className="w-full h-full min-h-[4in]"
                height="100%"
                width="100%"
                controls
                src={item.photo_url}
              ></video>
            )}
          </div>
        ))}
      {item.screenshot && item.type !== "photo_geolocation" && (
        <img
          className="w-full object-contain min-h-[4in] h-[4in]"
          src={`data:image/jpeg;base64,${decodeURIComponent(
            item.screenshot.replace("/media", "")
          )}`}
          alt=""
        />
      )}
      {item.screenshot && item.type === "photo_geolocation" && (
        <img
          className="w-full object-contain min-h-[4in] h-[4in]"
          src={decodeURIComponent(item.screenshot.replace("/media", ""))}
          alt=""
        />
      )}
      {title && <h3 className="text-2xl text-font font-medium">{title}</h3>}
      {description &&
        (item.type === "ai_satellite" ? (
          <Markdown components={markdownComponents}>{description}</Markdown>
        ) : (
          <p className="text-xl text-font opacity-80">{description}</p>
        ))}
      <div className="flex flex-col flex-1 justify-between">
        <CardContent className="px-0">
          <Attributes
            data={item}
            excludeKeys={[
              ...(title ? ["title", "name"] : []),
              ...(description ? ["description", "text", "summary"] : []),
            ]}
          />
          {item.type === "trestle" &&
            Array.isArray(item.people) &&
            item.people.length > 0 && (
              <div className="col-span-2 flex flex-col mt-4">
                <h4 className="text-[var(--text-color)] font-medium text-xl mb-4">
                  People
                </h4>
                <ScrollArea className="h-96">
                  {item.people.map((person, k) => (
                    <PersonRef
                      {...person}
                      key={`item-${item.id}-person-${k}`}
                    />
                  ))}
                </ScrollArea>
              </div>
            )}
        </CardContent>
        <CardFooter className="flex items-center gap-4 px-0 pb-0 pt-2">
          {item.type && (
            <Link
              to={`/module/${item.type}/item/${item.id}`}
              className="flex-1 font-medium text-base text-center z-10 !bg-[var(--primary-color2)] rounded-md py-2 px-4 text-white dark:text-black hover:opacity-80 transition-all"
            >
              Details
            </Link>
          )}
          <Link
            to={item.url}
            className="flex-1 font-medium bg-[var(--body-color)] rounded-md text-font text-base text-center z-10 border border-slate-600 py-2 px-4  transition-all"
          >
            Source
          </Link>
        </CardFooter>
      </div>
    </Card>
  );
}

const HslPlayer = memo(
  (item) => <ReactHlsPlayer src={item.photo_url} controls />,
  (prev, next) => prev.photo_url === next.photo_url
);

const PersonRef = (person) => (
  <div className="border-b last:border-b-0 border-slate-300 dark:border-[#1F242F] first:pt-0 last:pb-0 py-4">
    <Attributes data={person} />
  </div>
);
