import { SEARCH_OPTIONS } from "@/const/search";
import {
  MultiSelect,
  MultiSelectContent,
  MultiSelectInput,
  MultiSelectItem,
  MultiSelectList,
  MultiSelectTrigger,
} from "../ui/multi-select";
import { SelectGroup, SelectLabel } from "../ui/select";
import { Dispatch, SetStateAction, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "../ui/badge";
import { Repeat } from "lucide-react";

type Props = {
  availableValues: Record<string, Option<any>[]>;
  selectedValues: ValueLabel[];
  setSelectedValues: Dispatch<SetStateAction<ValueLabel[]>>;
};

const options = SEARCH_OPTIONS.flatMap((item) => item.options);

export default function Filter({
  availableValues,
  selectedValues,
  setSelectedValues,
}: Props) {
  return (
    <div className="relative flex items-center">
      <MultiSelect
        className="max-w-xs"
        values={selectedValues}
        onSelectionChange={(values) => setSelectedValues(values)}
      >
        <MultiSelectTrigger valuesClassName="hidden">
          <MultiSelectInput
            disabled={
              !Object.values(availableValues).some((opts) => opts.length > 0)
            }
            placeholder="Filter by type"
          />
        </MultiSelectTrigger>
        <MultiSelectContent>
          <MultiSelectList>
            {selectedValues.length < options.length && (
              <MultiSelectItem
                value={{ value: "all", label: "" }}
                onSelect={() => setSelectedValues(options)}
              >
                <Repeat size={14} />
                Reset
              </MultiSelectItem>
            )}
            {Object.entries(availableValues).map(([title, options]) => (
              <SelectGroup key={`group-${title}`}>
                <SelectLabel key={`label-${title}`}>{title}</SelectLabel>
                {options
                  .sort((a, b) =>
                    a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                  )
                  .filter((opt) => opt.search !== false)
                  .map((opt) => (
                    <MultiSelectItem value={opt} key={opt.value}>
                      {opt.icon &&
                        (typeof opt.icon === "function" ? (
                          <div className="fill-[var(--text-color)] stroke-[var(--text-color)] w-4">
                            <opt.icon />
                          </div>
                        ) : (
                          <FontAwesomeIcon
                            className="w-4"
                            size="lg"
                            icon={opt.icon}
                          />
                        ))}
                      {opt.label}
                    </MultiSelectItem>
                  ))}
              </SelectGroup>
            ))}
          </MultiSelectList>
        </MultiSelectContent>
      </MultiSelect>
      {selectedValues.length < options.length && (
        <Badge className="absolute right-3 !bg-[var(--primary-color2)]">
          {options.length - selectedValues.length}
        </Badge>
      )}
    </div>
  );
}
