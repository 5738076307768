import {
  faArrowDown,
  faArrowUp,
  faMoon,
  faSpinner,
  faSun,
  faWater,
  faWind,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function WeatherPopup({ weather, isLoading }) {
  return (
    <div className="w-full 2xl:max-w-max 2xl:min-w-96 absolute top-full left-0 2xl:left-1/2 2xl:-translate-x-1/2 right-0">
      <div className="bg-[var(--body-color)] text-[var(--text-color)] border border-t-0 border-slate-200 dark:border-[#1F242F] p-4">
        {!weather || isLoading ? (
          <div className="flex-1 grid place-content-center py-4">
            <l-waveform size={24} stroke={2} color="var(--text-color)" />
          </div>
        ) : (
          <div className="flex flex-col items-center gap-3">
            <div className="grid grid-cols-[minmax(5rem,1fr)_max-content_1fr] items-center gap-4">
              <img className="w-full" src={weather.icon} alt="" />
              <div className="flex flex-col mt-1">
                <strong className="text-[48px] font-semibold leading-none">
                  {weather.temp} <span>°</span>
                </strong>
                <span className="opacity-80 text-lg">
                  Feels like {weather.feels_like} <span>°</span>
                </span>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-1.5">
                  <FontAwesomeIcon icon={faWind} />
                  <span className="opacity-80 text-lg">
                    {weather.wind_speed} km / h
                  </span>
                </div>
                <div className="flex items-center gap-1.5">
                  <div className="flex items-center gap-0.5">
                    <FontAwesomeIcon width={8} icon={faArrowUp} />
                    <FontAwesomeIcon icon={faSun} />
                  </div>
                  <span className="opacity-80 text-lg">{weather.sunrise}</span>
                </div>
                <div className="flex items-center gap-1.5">
                  <div className="flex items-center gap-0.5">
                    <FontAwesomeIcon width={8} icon={faArrowDown} />
                    <FontAwesomeIcon icon={faSun} />
                  </div>
                  <span className="opacity-80 text-lg">{weather.sunset}</span>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <div className="flex items-center gap-1.5">
                <FontAwesomeIcon icon={faMoon} />
                <span className="opacity-80 text-lg">{weather.moonphase}</span>
              </div>
              <div className="flex items-center gap-1.5">
                <FontAwesomeIcon icon={faWater} />
                <span className="opacity-80 text-lg">{weather.humidity} %</span>
              </div>
              <div className="flex items-center gap-1.5">
                <FontAwesomeIcon icon={faArrowDown} />
                <span className="opacity-80 text-lg">
                  {weather.pressure} hPa
                </span>
              </div>
            </div>
            <p className="text-sm text-center">{weather.description}</p>
          </div>
        )}
      </div>
    </div>
  );
}
