import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import Markdown from "react-markdown";
import { ScrollArea } from "../ui/scroll-area";
import { Button } from "../ui/button";
import { useState } from "react";
import markdownComponents from "@/const/mdx-components";

export default function Analysis({ analysis }: { analysis: string }) {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <>
      <Button
        size="sm"
        className="max-w-max"
        variant="outline"
        onClick={() => setIsOpen(true)}
      >
        Analysis
      </Button>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="px-0 max-w-4xl">
          <DialogHeader className="px-6">
            <DialogTitle>Analysis</DialogTitle>
          </DialogHeader>
          <ScrollArea className="max-h-96 px-6">
            <Markdown className="grid gap-2" components={markdownComponents}>
              {analysis}
            </Markdown>
          </ScrollArea>
          <DialogFooter className="px-6">
            <DialogClose asChild>
              <Button
                type="button"
                variant="outline"
                className="text-[#252525] dark:text-[#F5F5F5]"
              >
                Close
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
