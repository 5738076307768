import { useContext, useEffect, useMemo, useRef, useState } from "react";
import MapContainer from "../../components/map/MapContainer";
import { WorkspaceContext } from "../../providers/workspace";
import LoadingBar from "react-top-loading-bar";
import { SearchIcon } from "lucide-react";
import Legend from "@/components/search/legend";
import { Input } from "@/components/ui/input";

import Menu from "@/components/search/menu/wrapper";
import { Switch } from "@/components/ui/switch";
import { useTheme } from "@/providers/theme";
import useFilters from "@/providers/search/filters";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { useAuth0 } from "@auth0/auth0-react";
import { getCoordinates, getUploadItems } from "@/utils/search/lib";
import useMap from "@/hooks/useMap";
import { cn } from "@/utils/cn";
import Filter from "@/components/search/filter";
import { SEARCH_OPTIONS } from "@/const/search";

export const SearchLocation = (props) => {
  const [showOnly, setShowOnly] = useState(
    SEARCH_OPTIONS.flatMap((item) => item.options)
  );
  const { workspace } = useContext(WorkspaceContext);
  const [selectedValues, setSelectedValues] = useState({ "Social Media": [] });
  // const [hidden, setHidden] = useState(null); // null | 'all' | 'selected'
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [place, setPlace] = useState({});
  const mapBounds = useRef({});
  const { isDark, changeTheme } = useTheme();
  const [searchBox, setSearchBox] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [bounds, setBounds] = useState(null);
  const [itemsBounds, setItemsBounds] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const { map } = useMap();
  // const { ref, isMenuVisible, setIsMenuVisible } = useMenu();
  const {
    error,
    progress,
    setLoadingRequests,
    searchId,
    setSearchId,
    setMarkerList,
    loadingRequests,
    markerList,
    weather,
    news,
    setWeather,
    setNews,
  } = useFilters();

  const availableToFilter = useMemo(() => {
    const prevSelectedValues = { ...selectedValues };
    markerList.forEach((marker) => {
      // get group title
      const category = SEARCH_OPTIONS.find((item) =>
        item.options.some((opt) => opt.value === marker.type)
      );
      if (!category) return;
      const referenceInSelectedValues = [
        ...(prevSelectedValues[category.title] || []),
      ];
      if (referenceInSelectedValues.some((opt) => opt.value === marker.type))
        return;
      prevSelectedValues[category.title] = [
        ...referenceInSelectedValues,
        category.options.find((opt) => opt.value === marker.type),
      ];
    });
    return prevSelectedValues;
  }, [selectedValues, markerList]);

  useEffect(() => {
    if (!workspace) return;
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const coordinatesData = await getCoordinates(token, workspace.id);
        const from_upload = coordinatesData.from_upload;
        const from_photo = coordinatesData.from_photo;

        const initialBounds = {
          north: coordinatesData.north_lat,
          south: coordinatesData.south_lat,
          east: coordinatesData.east_lng,
          west: coordinatesData.west_lng,
        };

        setBounds(initialBounds);

        if (!from_photo && !from_upload) {
          setIsLoading(false);
          return;
        }

        const data = await getUploadItems(token, workspace.id, {
          from_upload,
        });

        if (Array.isArray(data)) {
          setMarkerList((prev) => [...prev, ...data]);
          if (
            data.length === 0 ||
            !Array.isArray(data[0].objects) ||
            data[0].objects.length === 0
          )
            return;
          const itemsBounds = new window.google.maps.LatLngBounds();
          data[0].objects.forEach((obj) => {
            if (!obj.location) return;
            itemsBounds.extend(obj.location);
          });
          setItemsBounds(itemsBounds);
        }
        setIsLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently, workspace, setMarkerList]);

  useEffect(() => {
    if (!map || !itemsBounds) return;
    map.fitBounds(itemsBounds);
  }, [map, itemsBounds]);

  if (error) return <div>Error: {error.message}</div>;

  const onPlacesChanged = () => {
    const query = searchBox.getPlaces();
    const newLat = query[0].geometry.location.lat();
    const newLng = query[0].geometry.location.lng();
    // setCenter({ lat: newLat, lng: newLng });
    setPlace({ lat: newLat, lng: newLng });
  };

  return (
    <section
      className={`home transition-all ${
        props.sidebarShown ? "md:ml-[250px]" : "md:ml-[88px]"
      } flex flex-col w-full min-h-screen pt-2`}
    >
      <div className="relative flex items-center justify-between px-6 mb-2">
        <h1 className="text-xl text-[var(--text-color)] text-center font-medium">
          {workspace.title}
        </h1>
        <div className="flex-1 max-w-md">
          <StandaloneSearchBox
            onPlacesChanged={onPlacesChanged}
            onLoad={(box) => setSearchBox(box)}
            bounds={bounds}
          >
            <Input
              placeholder="Search"
              startContent={<SearchIcon size={16} />}
            />
          </StandaloneSearchBox>
        </div>
        <div className="flex items-center gap-4">
          <Filter
            availableValues={availableToFilter}
            selectedValues={showOnly}
            setSelectedValues={setShowOnly}
          />
          <div className="flex items-center gap-2">
            <Switch
              className="!bg-white dark:!bg-pink"
              checked={isDark}
              onCheckedChange={changeTheme}
            />
            <span className="text-[var(--text-color)] text-base opacity-80e">
              Dark mode
            </span>
          </div>
          <Legend />
        </div>
      </div>
      <div
        className={cn(
          "flex-1 grid transition-[grid-template-columns] overflow-hidden",
          isMenuOpen ? "grid-cols-[280px_1fr]" : "grid-cols-[0px_1fr]"
        )}
      >
        <Menu
          selected={selectedValues}
          onSelectionChange={(type, values) =>
            setSelectedValues((prev) => ({ ...prev, [type]: values }))
          }
          place={place}
          mapBounds={mapBounds.current}
          isMenuOpen={isMenuOpen}
          toggleMenu={() => setIsMenuOpen((prev) => !prev)}
        />
        <MapContainer
          place={place}
          changePlace={(coords) => setPlace(coords)}
          changeMapBounds={(bnds) => {
            const ne = bnds.getNorthEast();
            const sw = bnds.getSouthWest();
            mapBounds.current = {
              ne_lat: ne.lat(),
              ne_lng: ne.lng(),
              sw_lat: sw.lat(),
              sw_lng: sw.lng(),
            };
          }}
          bounds={bounds}
          showOnly={showOnly}
          isLoading={isLoading}
          markers={markerList}
          setMarkers={setMarkerList}
          mapBounds={mapBounds.current}
          setSearchId={setSearchId}
          weather={weather}
          setWeather={setWeather}
          setNews={setNews}
          news={news}
          loadingRequests={loadingRequests}
          changeLoadingRequest={(key, value) =>
            setLoadingRequests((prev) => ({ ...prev, [key]: value }))
          }
        />
      </div>
      <LoadingBar
        height={8}
        color="#ff00cd"
        progress={progress.value ? progress.value : searchId.value ? 5 : 0}
      />
    </section>
  );
};
