import SurveillanceCluster from "./imgs/surveillance_cluster.png";
import FlickrCluster from "./imgs/flickr_cluster.png";
import AirbnbCluster from "./imgs/airbnb_cluster.png";
import AlltrailsCluster from "./imgs/alltrails_cluster.png";
import AmberAlertCluster from "./imgs/amber_alerts_cluster.png";
import CrimesCluster from "./imgs/crimes_cluster.png";
import IncidentsCluster from "./imgs/incidents_cluster.png";
import SnapchatCluster from "./imgs/snapchat_cluster.png";
import SportsTrackerCluster from "./imgs/sportstracker_cluster.png";
import StravaCluster from "./imgs/strava_cluster.png";
import VesselsCluster from "./imgs/vessels_cluster.png";
import VkontakteCluster from "./imgs/vkontakte_cluster.png";
import WeiboCluster from "./imgs/weibo_cluster.png";
import WindyCluster from "./imgs/windy_cluster.png";
import WorldcamCluster from "./imgs/worldcam_cluster.png";
import YoutubeCluster from "./imgs/youtube_cluster.png";
import WifiCluster from "./imgs/wifi_cluster.png";
import BluetoothCluster from "./imgs/bluetooth_cluster.png";
import CellTowerCluster from "./imgs/cell_tower_cluster.png";
import IotCluster from "./imgs/iot_cluster.png";
import CveCluster from "./imgs/cve_cluster.png";
import ExposedCamerasCluster from "./imgs/exposed_cameras_cluster.png";
import IcsCluster from "./imgs/ics_cluster.png";
import TrafficCamerasCluster from "./imgs/traffic_cameras_cluster.png";
import PlanesCluster from "./imgs/planes_cluster.png";
import Surveillance from "./imgs/surveillance.png";
import Flickr from "./imgs/flickr_icon.png";
import Planes from "./imgs/planes_icon.png";
import Alltrails from "./imgs/alltrails_icon.png";
import AmberAlert from "./imgs/amber_alerts_icon.png";
import Amtrak from "./imgs/amtrak_icon.png";
import Bluetooth from "./imgs/bluetooth_icon.png";
import Incidents from "./imgs/incidents_icon.png";
import Instagram from "./imgs/instagram_icon.png";
import Snapchat from "./imgs/snapchat_icon.png";
import SportsTracker from "./imgs/sportstracker_icon.png";
import Strava from "./imgs/strava_icon.png";
import Crime from "./imgs/crime_icon.png";
import Vessel from "./imgs/vessels.png";
import Vkontakte from "./imgs/vkontakte_icon.png";
import Weibo from "./imgs/weibo_icon.png";
import Windy from "./imgs/windy_icon.png";
import Worldcam from "./imgs/worldcam_icon.png";
import Youtube from "./imgs/youtube_icon.png";
import Airbnb from "./imgs/airbnb_icon.png";
import Wifi from "./imgs/wifi_icon.png";
import CellTower from "./imgs/cell_tower_icon.png";
import Mqtt from "./imgs/mqtt_icon.png";
import Printer from "./imgs/printer_icon.png";
import Proxyshell from "./imgs/proxyshell_icon.png";
import Bluekeep from "./imgs/bluekeep_icon.png";
import Screenshots from "./imgs/screenshots_icon.png";
import ShodanCamerasIcon from "./imgs/shodan_cameras_icon.png";
import IcsIcon from "./imgs/ics_icon.png";
import NmeaIcon from "./imgs/nmea_icon.png";
import TridiumIcon from "./imgs/tridium_icon.png";
import NordexIcon from "./imgs/nordex_icon.png";
import TankIcon from "./imgs/tank_icon.png";
import BuildingIcon from "./imgs/building_icon.png";
import TrafficIcon from "./imgs/traffic_icon.png";
import WikilocIcon from "./imgs/wikiloc_icon.png";
import { faMountain, faPersonRunning, faShop, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import AmtrakCluster from "./imgs/amtrak_cluster.png";
import ExposedCamerasIcon from "./imgs/ExposedCamerasIcon";
import SurveillanceIcon from "./imgs/SurveillanceIcon";
import TrafficIconComponent from "./imgs/TrafficIcon";
import WorldcamIcon from "./imgs/WorldcamIcon";
import WindyIcon from "./imgs/WindyIcon";
import WikilocIconComponent from "./imgs/WikilocIcon";
import WikilocCluster from "./imgs/wikiloc_cluster.png";
import OffendersCluster from "./imgs/offenders_cluster.png";
import OffendersIconComponent from "./imgs/OffendersIcon";
import OffendersIcon from "./imgs/offenders_icon.png";
import TicketmasterIconComponent from "./imgs/TicketmasterIcon";
import TicketmasterIcon from "./imgs/ticketmaster_icon.png";
import TicketmasterCluster from "./imgs/ticketmaster_cluster.png";
import ZillowIconComponent from "./imgs/ZillowIcon";
import ZillowIcon from "./imgs/zillow_icon.png";
import ZillowCluster from "./imgs/zillow_cluster.png";
import EventbriteIconComponent from "./imgs/EventbriteIcon";
import EventbriteIcon from "./imgs/eventbrite_icon.png";
import EventbriteCluster from "./imgs/eventbrite_cluster.png";
import CraigslistIcon from "./imgs/craigslist_icon.png";
import CraigslistCluster from "./imgs/craigslist_cluster.png";
import MeetupIconComponent from "./imgs/MeetupIcon";
import MeetupIcon from "./imgs/meetup_icon.png";
import MeetupCluster from "./imgs/meetup_cluster.png";
import NextdoorIconComponent from "./imgs/NextdoorIcon";
import NextdoorIcon from "./imgs/nextdoor_icon.png";
import NextdoorCluster from "./imgs/nextdoor_cluster.png";
import AirbnbStatsList from "../../components/stats/social-media/airbnb";
import StravaStatsList from "../../components/stats/social-media/strava";
import AlltrailsStatsList from "../../components/stats/social-media/alltrails";
import VKontakteStatsList from "../../components/stats/social-media/vkontakte";
import SnapchatStatsList from "../../components/stats/social-media/snapchat";
import YoutubeStatsList from "../../components/stats/social-media/youtube";
import WikilocStatsList from "../../components/stats/social-media/wikiloc";
import FlickrStatsList from "../../components/stats/social-media/flickr";
import { POLYGON_MODULES } from "../general";
import CraigslistStatsList from "../../components/stats/events/craigslist";
import NextdoorStatsList from "../../components/stats/events/nextdoor";
import ZillowStatsList from "../../components/stats/events/zillow";
import CrimesStatsList from "../../components/stats/events/crimes";
import NetworkStatsList from "../../components/stats/network";
import SurveillanceStatsList from "../../components/stats/surveillance";
import MeetupStatsList from "../../components/stats/events/meetup";
import EventBriteStatsList from "../../components/stats/events/eventbrite";
import DiveboardIcon from "./imgs/diveboard_icon.png";
import DiveboardCluster from "./imgs/diveboard_cluster.png";
import DiveboardIconComponent from "./imgs/DiveboardIcon";
import ExperiencesIcon from "./imgs/experiences_icon.jpg";
import ExperiencesCluster from "./imgs/experiences_cluster.png";
import OutdooractiveIcon from "./imgs/outdooractive_icon.png";
import OutdooractiveCluster from "./imgs/outdooractive_cluster.png";
import OutdooractiveComponent from "./imgs/OutdooractiveIcon";
import WazeIcon from "./imgs/waze_icon2.png";
import WazeCluster from "./imgs/waze_cluster.png";
import PoliceCluster from "./imgs/police_cluster.png";
import PoliceIcon from "./imgs/police_icon.png";
import NewsIcon from "./imgs/news_icon.png";
import NewsCluster from "./imgs/news_cluster.png";
import SnmpIcon from "./imgs/snmp_icon.png";
import KasaIcon from "./imgs/kasa_icon.png";
import FindpenguinsIcon from "./imgs/findpenguins_icon.png";
import FindpenguinsIconComponent from "./imgs/FindpenguinsIcon";
import FindpenguinsCluster from "./imgs/findpenguins_cluster.png";
import { faBicycle } from "@fortawesome/free-solid-svg-icons";
import BikemapIcon from "./imgs/bikemap_icon.png";
import BikemapCluster from "./imgs/bikemap_cluster.png";
import AcledComponent from "./imgs/AcledIcon";
import ProtestsIcon from "./imgs/protests_icon.png";
import ProtestsCluster from "./imgs/protests_cluster.png";
import Events3Icon from "./imgs/events3_icon.png";
import Events3Cluster from "./imgs/events3_cluster.png";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { faBolt, faPeopleRobbery } from "@fortawesome/free-solid-svg-icons";
import Events3Component from "./imgs/Events3Icon";
import Events2Icon from "./imgs/events2_icon.png";
import Events2Cluster from "./imgs/events2_cluster.png";
import Events1Icon from "./imgs/events1_icon.png";
import Events1Cluster from "./imgs/events1_cluster.png";
import PhotoGeolocationIcon from "./imgs/photo_geolocation_icon.png";
import PhotoGeolocationCluster from "./imgs/photo_geolocation_cluster.png";
import UploadedIcon from "./imgs/uploaded_icon.png";
import UploadedCluster from "./imgs/uploaded_cluster.png";
import OsmIcon from "./imgs/osm_icon.png";
import OsmCluster from "./imgs/osm_cluster.png";
import Crimes2Icon from "./imgs/crimes2_icon.png";
import Crimes2Cluster from "./imgs/crimes2_cluster.png";
import Events4Icon from "./imgs/events4_icon.png";
import Events4Cluster from "./imgs/events4_cluster.png";
import Events4Component from "./imgs/Events4Icon";
import Events5Icon from "./imgs/events5_icon.png";
import Events5Cluster from "./imgs/events5_cluster.png";
import Events5Component from "./imgs/Events5Icon";
import BookingIcon from "./imgs/booking_icon.png";
import BookingCluster from "./imgs/booking_cluster.png";
import BookingComponent from "./imgs/BookingIcon";
import TiktokIcon from "./imgs/tiktok_icon.png";
import TiktokCluster from "./imgs/tiktok_cluster.png";
import TwitterIcon from "./imgs/twitter_icon.png";
import TwitterCluster from "./imgs/twitter_cluster.png";
import InstagramIcon from "./imgs/instagram_icon.png";
import InstagramCluster from "./imgs/instagram_cluster.png";
import FacebookIcon from "./imgs/facebook_icon.png";
import FacebookCluster from "./imgs/facebook_cluster.png";
import TelegramCluster from "./imgs/telegram_cluster.png";
import TelegramIcon from "./imgs/telegram_icon.png";
import TrestleIcon from "./imgs/trestle_icon.png";
import TrestleCluster from "./imgs/trestle_cluster.png";
import WifiIcon from "./imgs/wifi_icon.png";
import SatelliteIcon from "./imgs/satellite_icon.png";
import SatelliteCluster from "./imgs/satellite_cluster.png";
import FacebookEventsIcon from "./imgs/facebook_events_icon.png";
import FacebookEventsCluster from "./imgs/facebook_events_cluster.png";
import FacebookMarketplaceIcon from "./imgs/facebook_marketplace_icon.png";
import FacebookMarketplaceCluster from "./imgs/facebook_marketplace_cluster.png";
import StoresIcon from "./imgs/stores_icon.png";
import StoresCluster from "./imgs/stores_cluster.png";
import AirportsIcon from "./imgs/airports_icon.png";
import AirportsCluster from "./imgs/airports_cluster.png";
import StadiumIcon from "./imgs/stadium_icon.png";
import StadiumCluster from "./imgs/stadium_cluster.png";
import DisastersIcon from "./imgs/disasters_icon.png";
import DisastersCluster from "./imgs/disasters_cluster.png";
import WeatherIcon from "./imgs/weather_icon.png";

export const clusterIcons = {
    disasters: DisastersCluster,
    stadium: StadiumCluster,
    airports: AirportsCluster,
    stores: StoresCluster,
    facebook_marketplace: FacebookMarketplaceCluster,
    facebook_events: FacebookEventsCluster,
  ai_satellite: SatelliteCluster,
  wifi: WifiCluster,
  trestle: TrestleCluster,
  telegram: TelegramCluster,
  facebook: FacebookCluster,
  instagram: InstagramCluster,
  twitter: TwitterCluster,
  tiktok: TiktokCluster,
  booking: BookingCluster,
  events5: Events5Cluster,
  events4: Events4Cluster,
  crimes2: Crimes2Cluster,
  osm: OsmCluster,
  uploaded_items: UploadedCluster,
  photo_geolocation: PhotoGeolocationCluster,
  events1: Events1Cluster,
  events2: Events2Cluster,
  events3: Events3Cluster,
  protests: ProtestsCluster,
  findpenguins: FindpenguinsCluster,
  news: NewsCluster,
  police: PoliceCluster,
  waze: WazeCluster,
  bikemap: BikemapCluster,
  outdooractive: OutdooractiveCluster,
  experiences: ExperiencesCluster,
  diveboard: DiveboardCluster,
  nextdoor: NextdoorCluster,
  meetup: MeetupCluster,
  craigslist: CraigslistCluster,
  eventbrite: EventbriteCluster,
  zillow: ZillowCluster,
  wikiloc: WikilocCluster,
  amtrak: AmtrakCluster,
  flickr: FlickrCluster,
  surveillance: SurveillanceCluster,
  airbnb: AirbnbCluster,
  alltrails: AlltrailsCluster,
  amber_alert: AmberAlertCluster,
  incidents: IncidentsCluster,
  //  instagram: InstagramCluster,
  snapchat: SnapchatCluster,
  sports_tracker: SportsTrackerCluster,
  strava: StravaCluster,
  vessel: VesselsCluster,
  vkontakte: VkontakteCluster,
  weibo: WeiboCluster,
  windy: WindyCluster,
  worldcam: WorldcamCluster,
  youtube: YoutubeCluster,
  network: WifiCluster,
  bluetooth: BluetoothCluster,
  cell: CellTowerCluster,
  cell_tower: CellTowerCluster,
  mqtt: IotCluster,
  printers: IotCluster,
  bluekeep: CveCluster,
  proxyshell: CveCluster,
  vulnerabilities: CveCluster,
  screenshots: ExposedCamerasCluster,
  netwave: ExposedCamerasCluster,
  hikvision: ExposedCamerasCluster,
  rtsp: ExposedCamerasCluster,
  exposed_cameras: ExposedCamerasCluster,
  ics: IcsCluster,
  amcrest: ExposedCamerasCluster,
  dahua: ExposedCamerasCluster,
  dlink: ExposedCamerasCluster,
  axis: ExposedCamerasCluster,
  blueiris: ExposedCamerasCluster,
  geohttpserver: ExposedCamerasCluster,
  mobotix: ExposedCamerasCluster,
  avtech: ExposedCamerasCluster,
  motioneye: ExposedCamerasCluster,
  tridium: IcsCluster,
  modbus: IcsCluster,
  bacnet: IcsCluster,
  tank: IcsCluster,
  nordex: IcsCluster,
  "ipc@chip": IcsCluster,
  red: IcsCluster,
  omron: IcsCluster,
  iec: IcsCluster,
  moxa: IcsCluster,
  crestron: IcsCluster,
  siemens: IcsCluster,
  building: IcsCluster,
  nmea: IcsCluster,
  crimes: CrimesCluster,
  amber_alerts: AmberAlertCluster,
  traffic: TrafficCamerasCluster,
  planes: PlanesCluster,
  iot: IotCluster,
  offenders: OffendersCluster,
  ticketmaster: TicketmasterCluster,
  snmp: IotCluster,
  kasa: IotCluster,
};

export const SEARCH_OPTIONS: SearchOption[] = [
  {
    title: "Social Media",
    options: [
      {
        value: "airbnb",
        label: "Airbnb",
        icon: ["fab", "airbnb"],
        stats: AirbnbStatsList,
        color: "#FF5A60",
        searchUsername: false,
        workBbox: true,
        workTimestamp: true,
        workKeyword: true,
      },
      {
        value: "alltrails",
        label: "Alltrails",
        icon: faMountain,
        authorData: false,
        stats: AlltrailsStatsList,
        color: "#64F67A",
        workBbox: true,
        workTimestamp: true,
        workKeyword: true,
      },
      {
        value: "bikemap",
        label: "Bikemap",
        icon: faBicycle,
        authorData: true,
        color: "#0099CC",
        workBbox: true,
        workTimestamp: true,
        workKeyword: true,
      },
      {
        value: "booking",
        label: "Booking",
        icon: BookingComponent,
        authorData: true,
        color: "#0099CC",
        workBbox: true,
        workTimestamp: true,
        workKeyword: true,
      },
      {
        value: "diveboard",
        label: "Diveboard",
        icon: DiveboardIconComponent,
        workBbox: true,
        workTimestamp: true,
        workKeyword: true,
      },
      {
        value: "findpenguins",
        label: "Findpenguins",
        icon: FindpenguinsIconComponent,
        workBbox: true,
        authorData: false,
      },

      {
        value: "flickr",
        label: "Flickr",
        icon: ["fab", "flickr"],
        stats: FlickrStatsList,
        color: "#FF0084",
        workRadius: true,
        workTimestamp: true,
        workKeyword: true,
      },
      {
        value: "outdooractive",
        label: "Outdooractive",
        icon: OutdooractiveComponent,
        authorData: true,
        color: "#A6B338",
        workBbox: true,
        workTimestamp: true,
      },

      {
        value: "instagram",
        label: "Instagram",
        icon: ["fab", "instagram"],
        authorData: true,
        color: "#E1306C",
        search: false,
      },
      {
        value: "snapchat",
        label: "Snapchat",
        icon: ["fab", "snapchat"],
        authorData: false,
        stats: SnapchatStatsList,
        color: "#FFFC00",
        workRadius: true,
        workTimestamp: true,
      },
      {
        value: "sports_tracker",
        label: "Sportstracker",
        icon: faPersonRunning,
        searchUsername: true,
        color: "#E85047",
        workBbox: true,
        workTimestamp: true,
        workKeyword: true,
      },
      {
        value: "tiktok",
        label: "Tiktok",
        icon: TiktokIcon,
        color: "#25F4EE",
        search: false,
        authorData: true,
      },
      {
        value: "facebook",
        label: "Facebook",
        icon: FacebookIcon,
        color: "#0165E1",
        search: false,
        authorData: true,
      },
      // {
      //   value: "strava",
      //   label: "Strava",
      //   icon: ["fab", "strava"],
      //   stats: StravaStatsList,
      //   color: "#F44903",
      //   searchUsername: true,
      // },
      //       {
      //         value: "telegram",
      //         label: "Telegram* (Premium)",
      //         icon: ["fab", "telegram"],
      //         color: "#0088cc",
      //         workRadius: true,
      //         workTimestamp: false,
      //         workKeyword: false,
      //         workLonger: false,
      //         authorData: false,
      //       },
      {
        value: "trestle",
        label: "Trestle",
        icon: ["fab", "house-user"],
        color: "#1DA1F2",
        workRadius: true,
        workTimestamp: false,
        workKeyword: false,
        workLonger: true,
        authorData: true,
        search: false,
      },
      {
        value: "twitter",
        label: "Twitter",
        icon: ["fab", "twitter"],
        color: "#1DA1F2",
        workRadius: true,
        workTimestamp: true,
        workKeyword: true,
        workLonger: true,
      },
      {
        value: "vkontakte",
        label: "Vkontakte",
        icon: ["fab", "vk"],
        stats: VKontakteStatsList,
        color: "#2787F5",
        workRadius: true,
        workTimestamp: true,
        workKeyword: true,
      },
      {
        value: "weibo",
        label: "Weibo",
        icon: ["fab", "weibo"],
        color: "#E6162D",
        workRadius: true,
        workTimestamp: true,
        workKeyword: true,
      },
      {
        value: "wikiloc",
        label: "Wikiloc",
        icon: WikilocIconComponent,
        stats: WikilocStatsList,
        color: "#4B8C2A",
        searchUsername: true,
        workBbox: true,
        workKeyword: true,
      },
      {
        value: "youtube",
        label: "Youtube",
        icon: ["fab", "youtube"],
        stats: YoutubeStatsList,
        color: "#FF0000",
        searchUsername: true,
        workRadius: true,
        workTImestamp: true,
        workKeyword: true,
      },
    ],
    areCameras: false,
    authorData: true,
  },
  {
    title: "Public Cameras",
    options: [
      {
        value: "surveillance",
        label: "Surveillance",
        stats: SurveillanceStatsList,
        icon: SurveillanceIcon,
        color: "#584193",
        workRadius: true,
      },
      {
        value: "traffic_cameras",
        label: "Traffic",
        icon: TrafficIconComponent,
        onlyUsa: true,
        workBbox: true,
      },
      {
        value: "windy",
        label: "Windy",
        icon: WindyIcon,
        color: "#6C009F",
        workBbox: true,
      },
      {
        value: "worldcam",
        label: "Worldcam",
        icon: WorldcamIcon,
        color: "#581FA2",
        workBbox: true,
      },
    ],
    areCameras: false,
    authorData: false,
  },
  {
    title: "Internet facing cameras",
    options: [
      {
        value: "screenshots",
        label: "Screenshots",
        icon: ExposedCamerasIcon,
      },
      { value: "rtsp", label: "RTSP", icon: "", workRadius: true },
      { value: "amcrest", label: "Amcrest", icon: "", workRadius: true },
      { value: "netwave", label: "Netwave", icon: "", workRadius: true },
      { value: "hikvision", label: "Hikvision", icon: "", workRadius: true },
      { value: "dlink", label: "DLink", icon: "", workRadius: true },
      { value: "dahua", label: "Dahua", icon: "", workRadius: true },
      { value: "axis", label: "Axis", icon: "", workRadius: true },
      { value: "blueiris", label: "Blueiris", icon: "", workRadius: true },
      {
        value: "geohttpserver",
        label: "GeoHTTPServer",
        icon: "",
        workRadius: true,
      },
      { value: "mobotix", label: "Mobotix", icon: "", workRadius: true },
      { value: "avtech", label: "Avtech", icon: "", workRadius: true },
      { value: "motioneye", label: "Motioneye", icon: "", workRadius: true },
    ],
    areCameras: true,
    authorData: false,
  },
  {
    title: "Industrial control systems",
    options: [
      { value: "bacnet", label: "Bacnet", icon: "", workRadius: true },
      {
        value: "tridium",
        label: "Tridium Niagara",
        icon: "",
        workRadius: true,
      },
      { value: "tank", label: "Fuel tank", icon: "", workRadius: true },
      {
        value: "nordex",
        label: "Nordex wind turbine",
        icon: "",
        workRadius: true,
      },
      { value: "ipc@chip", label: "IPC@CHIP", icon: "", workRadius: true },
      { value: "red", label: "Red Lion Controls", icon: "", workRadius: true },
      { value: "omron", label: "Omron", icon: "", workRadius: true },
      { value: "iec", label: "IEC 60870-5-104", icon: "", workRadius: true },
      { value: "siemens", label: "Siemens", icon: "", workRadius: true },
      { value: "modbus", label: "Modbus", icon: "", workRadius: true },
      {
        value: "building",
        label: "Building Operation Workstation",
        icon: "",
        workRadius: true,
      },
      { value: "nmea", label: "NMEA", icon: "", workRadius: true },
    ],
    areCameras: true,
    authorData: false,
  },
  {
    title: "Internet of things",
    options: [
      { value: "mqtt", label: "MQTT", icon: "", workRadius: true },
      { value: "printers", label: "Printers", icon: "", workRadius: true },
      { value: "kasa", label: "Kasa TP-Link", icon: "", workRadius: true },
      { value: "snmp", label: "SNMP", icon: "", workRadius: true },
    ],
    areCameras: true,
    authorData: false,
  },
  {
    title: "Vulnerabilities",
    options: [
      { value: "bluekeep", label: "Bluekeep", icon: "", workRadius: true },
      { value: "proxyshell", label: "Proxyshell", icon: "", workRadius: true },
    ],
    areCameras: true,
    authorData: false,
  },
  {
    title: "Networks",
    options: [
      {
        value: "bluetooth",
        label: "Bluetooth",
        icon: ["fab", "bluetooth"],
        workBbox: true,
        workKeyword: true,
        workLonger: true,
      },
      {
        value: "cell_tower",
        label: "Cell Tower",
        icon: icon({ name: "tower-cell", style: "solid" }),
        workBbox: true,
        workKeyword: true,
        workLonger: true,
      },
      {
        value: "wifi",
        label: "Wifi",
        stats: NetworkStatsList,
        icon: icon({ name: "wifi", style: "solid" }),
        workBbox: true,
        workKeyword: true,
        workLonger: true,
      },
    ],
    areCameras: false,
    authorData: false,
  },
  {
    title: "Events",
    options: [
      {
        value: "experiences",
        label: "Airbnb Experiences",
        icon: ["fab", "airbnb"],
        authorData: false,
        workBbox: true,
        workTimestamp: true,
        workKeyword: true,
      },

      {
        value: "amber_alerts",
        label: "Amber Alerts",
        icon: icon({ name: "child", style: "solid" }),
        authorData: false,
        color: "#FFD700",
        onlyUsa: true,
        workBbox: true,
        workTimestamp: true,
      },
      {
        value: "craigslist",
        label: "Craigslist",
        icon: icon({ name: "peace", style: "solid" }),
        stats: CraigslistStatsList,
        authorData: false,
        color: "#38006b",
        onlyUsa: true,
        workBbox: true,
        workKeyword: true,
      },
      {
        value: "crimes",
        label: "Crimes",
        icon: icon({ name: "handcuffs", style: "solid" }),
        stats: CrimesStatsList,
        authorData: false,
        color: "#6D150E",
        onlyUsa: true,
        workRadius: true,
        workTimestamp: true,
        workKeyword: true,
      },
      {
        value: "crimes2",
        label: "Crimes 2",
        icon: faPeopleRobbery,
        authorData: false,
        color: "#ed1c24",
        onlyUsa: true,
        workBbox: true,
        workTimestamp: true,
        workKeyword: true,
      },
      {
        value: "eventbrite",
        label: "Eventbrite",
        icon: EventbriteIconComponent,
        stats: EventBriteStatsList,
        color: "#ff8000",
        workBbox: true,
        workTimestamp: true,
        workKeyword: true,
      },
      {
        value: "events1",
        label: "Events 1",
        icon: faBolt,
        // stats: EventBriteStatsList,
        // color bloody red
        color: "rgba(255,0,111,0.71)",
        authorData: false,
        workBbox: true,
        workTimestamp: true,
        workKeyword: true,
      },
      {
        value: "events2",
        label: "Events 2",
        icon: faBullhorn,
        color: "FF006FB5",
        authorData: false,
        workBbox: true,
        workTimestamp: true,
        workKeyword: true,
      },
      {
        value: "events3",
        label: "Events 3",
        icon: Events3Component,
        authorData: false,
        color: "rgba(255,0,38,0.81)",
        workBbox: true,
        workLonger: true,
      },
      {
        value: "events4",
        label: "Events 4",
        icon: Events4Component,
        authorData: false,
        color: "rgba(255,0,38,0.81)",
        workBbox: true,
        workTimestamp: true,
        workKeyword: true,
      },
      {
        value: "events5",
        label: "Events 5",
        icon: Events5Component,
        authorData: false,
        color: "rgba(255,0,38,0.81)",
        onlyUsa: true,
        workBbox: true,
        workTimestamp: true,
        workKeyword: true,
      },
        {
        value: "facebook_marketplace",
        label: "FB Marketplace",
        icon: faShop,
        authorData: false,
        color: "rgba(255,0,38,0.81)",
        onlyUsa: false,
        workBbox: false,
        workTimestamp: false,
        workKeyword: true,
        workRadius: true,
      },
        {
        value: "facebook_events",
        label: "FB Events",
        icon: faCalendar,
        authorData: false,
        color: "rgba(255,0,38,0.81)",
        onlyUsa: false,
        workBbox: false,
        workRadius: true,
        workTimestamp: false,
        workKeyword: false,
      },
      {
        value: "news",
        label: "News",
        color: "#FF0000",
        search: false,
        authorData: false,
      },
      //       {
      //         value: "meetup",
      //         label: "Meetup",
      //         stats: MeetupStatsList,
      //         icon: MeetupIconComponent,
      //         color: "#e51937",
      //         workRadius: true,
      //         workTimestamp: true,
      //         workKeyword: true,
      //       },
      // {
      //   value: "nextdoor",
      //   label: "Nextdoor",
      //   stats: NextdoorStatsList,
      //   icon: NextdoorIconComponent,
      //   color: "#19975d",
      // },
      {
        value: "offenders",
        label: "Offenders",
        icon: OffendersIconComponent,
        onlyUsa: true,
        workBbox: true,
        workLonger: true,
      },
      {
        value: "protests",
        label: "Protests",
        icon: AcledComponent,
        authorData: false,
        workRadius: true,
        color: "#FFEA12",
        workTimestamp: true,
        workKeyword: true,
      },
      {
        value: "ticketmaster",
        label: "Ticketmaster",
        icon: TicketmasterIconComponent,
        color: "#008CFF",
        workRadius: true,
        workTimestamp: true,
        workKeyword: true,
      },
      {
        value: "zillow",
        label: "Zillow",
        icon: ZillowIconComponent,
        stats: ZillowStatsList,
        authorData: false,
        color: "#1277e1",
        workBbox: true,
      },
    ],
    areCameras: false,
    authorData: true,
  },
];

export const CAMERAS_MODULES = SEARCH_OPTIONS.filter((item) => item.areCameras)
  .flatMap((item) => item.options)
  .map((item) => item.value);

export const DIRECTIONS_MODULES = ["sports_tracker", "strava"];

export const TRANSPORT_MODULES: Transport[] = [
  {
    name: "planes",
    speed: 130,
    type: {
      field: "wake",
      options: [
        {
          label: "L",
          value: "light",
          size: 10,
          strokeColor: "#000",
          fillColor: "#EBE208",
          anchor: {
            x: 215.1,
            y: 219.85,
          },
        },
        {
          label: "M",
          value: "medium",
          size: 18,
          strokeColor: "#000",
          fillColor: "#EBE208",
          anchor: {
            x: -31.9,
            y: 357.65,
          },
        },
        {
          label: "H",
          value: "heavy",
          size: 4,
          strokeWidth: 1,
          strokeColor: "#1D1D1B",
          fillColor: "#EBE208",
          anchor: {
            x: 429.15,
            y: 257.8,
          },
        },
      ],
      default: {
        value: "medium",
        label: "medium",
        size: 18,
        strokeColor: "#000",
        fillColor: "#EBE208",
        anchor: {
          x: -31.9,
          y: 357.65,
        },
      },
    },
  },
  {
    name: "vessel",
    speed: 2,
    type: {
      field: "vessel_type",
      options: [
        {
          label: "Cargo",
          value: "cargo",
          size: 90,
          strokeWidth: 0.25,
          fillColor: "#EC6D1C",
          strokeColor: "#000000",
          anchor: {
            x: -282.9,
            y: 422.3,
          },
        },
        {
          label: "Pleasure Craft",
          value: "pleasure",
          size: 70,
          strokeColor: "#1D1D1B",
          fillColor: "#3DAA35",
          anchor: {
            x: -277.7,
            y: 413.05,
          },
        },
        {
          label: "Sailing",
          value: "sailing",
          size: 70,
          fillColor: "#EFEB8A",
          strokeColor: "#000000",
          anchor: {
            x: -284.8,
            y: 415.55,
          },
        },
        {
          label: "Tanker",
          value: "tanker",
          size: 90,
          strokeWidth: 0.25,
          fillColor: "#E2DF04",
          strokeColor: "#1D1D1B",
          anchor: {
            x: -284.3,
            y: 420.7,
          },
        },
        {
          label: "Passenger",
          value: "passenger",
          size: 70,
          strokeColor: "#1D1D1B",
          strokeWidth: 0.25,
          fillColor: "#FFFFFF",
          anchor: {
            x: -280.15,
            y: 414.85,
          },
        },
        {
          label: "Fishing",
          value: "fishing",
          size: 70,
          strokeWidth: 0.25,
          fillColor: "#5E4010",
          strokeColor: "#000000",
          anchor: {
            x: -256.45,
            y: 414.45,
          },
        },
        {
          label: "Other",
          value: "other",
          size: 70,
          fillColor: "#6B6B6B",
          strokeWidth: 0.25,
          strokeColor: "#1D1D1B",
          anchor: {
            x: -282.5,
            y: 415.7,
          },
        },
      ],
      default: {
        value: "other",
        label: "other",
        size: 70,
        fillColor: "#6B6B6B",
        strokeWidth: 0.25,
        strokeColor: "#1D1D1B",
        anchor: {
          x: -282.5,
          y: 415.7,
        },
      },
    },
  },
];

export const MODULES = [
  ...SEARCH_OPTIONS.flatMap((item) => item.options),
  ...POLYGON_MODULES,
  {
    value: "incidents",
    color: "#E3BB28",
  },
  {
    value: "waze",
    color: "#05c8f7",
  },
  {
    value: "police",
    color: "#2A7A93",
  },
  {
    value: "news",
    color: "#36454f",
  },
  {
    value: "weather",
    color: "#ffffd2",
  },
];

export const TRANSPORT_TYPES = TRANSPORT_MODULES.map((item) => item.name);

export const ICON_TYPES = {
  osm: OsmIcon,
  news: NewsIcon,
  police: PoliceIcon,
  waze: WazeIcon,
  outdooractive: OutdooractiveIcon,
  experiences: ExperiencesIcon,
  diveboard: DiveboardIcon,
  craigslist: CraigslistIcon,
  zillow: ZillowIcon,
  ticketmaster: TicketmasterIcon,
  flickr: Flickr,
  surveillance: Surveillance,
  airbnb: Airbnb,
  alltrails: Alltrails,
  amtrak: Amtrak,
  crimes: Crime,
  incidents: Incidents,
  //  instagram: Instagram,
  snapchat: Snapchat,
  eventbrite: EventbriteIcon,
  sports_tracker: SportsTracker,
  strava: Strava,
  vessel: Vessel,
  vkontakte: Vkontakte,
  weibo: Weibo,
  windy: Windy,
  worldcam: Worldcam,
  youtube: Youtube,
  amber_alerts: AmberAlert,
  bluetooth: Bluetooth,
  network: Wifi,
  cell_tower: CellTower,
  mqtt: Mqtt,
  printers: Printer,
  bluekeep: Bluekeep,
  proxyshell: Proxyshell,
  screenshots: Screenshots,
  rtsp: ShodanCamerasIcon,
  netwave: ShodanCamerasIcon,
  amcrest: ShodanCamerasIcon,
  dahua: ShodanCamerasIcon,
  hikvision: ShodanCamerasIcon,
  dlink: ShodanCamerasIcon,
  axis: ShodanCamerasIcon,
  blueiris: ShodanCamerasIcon,
  geohttpserver: ShodanCamerasIcon,
  mobotix: ShodanCamerasIcon,
  avtech: ShodanCamerasIcon,
  motioneye: ShodanCamerasIcon,
  tridium: IcsIcon,
  bacnet: IcsIcon,
  modbus: IcsIcon,
  tank: IcsIcon,
  nordex: NordexIcon,
  "ipc@chip": IcsIcon,
  red: IcsIcon,
  omron: IcsIcon,
  iec: IcsIcon,
  moxa: IcsIcon,
  crestron: IcsIcon,
  siemens: IcsIcon,
  building: BuildingIcon,
  nmea: NmeaIcon,
  traffic: TrafficIcon,
  planes: Planes,
  "planes-light":
    "M430.2,437.7v-25.3l-109.8-35c0.3-21.4,0.3-42.9-0.4-65c-1.2-26-11.3-47-22.2-47c-10.9,0-20.9,21-22.2,47c-0.7,22-0.7,43.6-0.4,65l-109.8,35v25.3h111.6c1.4,34.3,3.1,68.2,4.3,102.3l-34,18.8v16.7h50.4h50.4v-16.7l-34-18.8c1.2-34.1,2.9-68.1,4.3-102.3H430.2z",
  "planes-medium":
    "M-43.7,303.5c-4.4,2.2-7.8,11-9.8,25.1c-1.4,9.9-1.9,17.5-2.2,37l-0.3,18.2l-8.3,4.5c-4.5,2.5-8.3,4.4-8.5,4.4s-0.3-3.4-0.3-7.6c0-6.9-0.1-7.6-0.9-8.6c-1.2-1.3-3.2-1.6-8.1-1.4c-3.8,0.2-4.1,0.3-5.1,1.5c-1.1,1.3-1.1,1.3-1.1,13v11.7l-29.1,15.6l-29.1,15.5v6v6l1.3,0.1c0.6,0.1,16.7-3.8,35.6-8.7l34.3-8.9h9.7h9.7v2.4c0,1.3,0.2,8.9,0.5,16.8c0.6,20.5,1.6,31.9,4.3,51.3c0.8,5.8,1.4,10.7,1.3,10.8s-6.6,4.1-14.6,8.9c-7.9,4.8-14.5,8.9-14.7,9.2c-0.2,0.3-0.3,1.9-0.2,3.7c0.1,2.4,0.3,3.3,0.9,3.7c0.6,0.4,4.7-0.6,16.5-3.7c8.7-2.3,15.8-4.2,15.9-4.1c0,0,0.6,2.1,1.3,4.5c1.4,5.2,3,8.5,3.9,8.5c0.9,0,2.5-3.3,3.9-8.5c0.6-2.4,1.3-4.4,1.3-4.5c0.1-0.1,7.2,1.8,15.9,4.1c11.8,3.2,15.9,4.1,16.5,3.7c0.6-0.3,0.8-1.3,0.9-3.7c0-1.8,0-3.4-0.2-3.7c-0.2-0.3-6.8-4.4-14.7-9.2s-14.5-8.8-14.6-8.9c-0.1-0.1,0.5-5,1.3-10.8c2.8-19.8,3.7-30.9,4.3-52.5c0.2-8.5,0.5-16.1,0.5-16.8v-1.3h9.7h9.7l34.3,8.9c18.9,4.8,34.9,8.8,35.6,8.7l1.3-0.1v-6v-6l-29.1-15.6L6.7,401.2v-11.7c0-11.7,0-11.7-1.1-13c-1.1-1.2-1.4-1.3-5.1-1.5c-4.8-0.2-7,0.2-8.2,1.4c-0.8,0.8-0.9,1.6-0.9,8.5c0,4.2-0.1,7.6-0.2,7.6c-0.1,0-3.9-2-8.5-4.4l-8.3-4.4l-0.7-17.8c-0.5-25.3-1.5-37.5-4.3-48.2c-1.5-5.9-4.3-11.8-6.4-13.3C-39.1,302.8-41.6,302.5-43.7,303.5z",
  "planes-heavy":
    "M555.8,312l0.1-25.1c0.1-21.3-8.9-32-27.3-32.1c-20.8-0.1-31.4,19.6-31.5,59.2l-0.2,41.4l-83.6,62.2l0.1-29.6c0.1-19.8-9-29.7-27.2-29.8c-21-0.1-31.4,13.6-31.5,41.2l-0.3,60.4C260.1,529.3,212.9,573,212.9,591.4c0,8.5,3.4,17.7,10.3,27.7c6.8,9.9,14.2,14.9,22,14.9c7.8,0,28.1-10.4,60.9-31.3c32.7-20.9,93.8-51.3,183.2-90.9c89.4-39.7,143.6-59.5,162.2-59.4c11.9,0.1,18.4,18,19.4,53.8c0.2,117.7,5.4,202.2,15.6,253.3c-5.2,5.9-24.2,20.5-56.9,43.6c-32.7,23.2-52.2,39.1-58.3,47.8c-6.2,8.8-9.3,24.3-9.4,46.6c-0.1,11.9,1.6,20,5,24.1c14.5,0.1,67-17.4,157.3-52.4c81.9,35.8,134.1,53.7,156.9,53.8c3.3-4.1,5.1-12.1,5.1-24c0.1-22.4-2.8-37.8-8.8-46.4c-5.9-8.7-25.2-24.8-57.9-48.4c-32.7-23.6-51.6-38.4-56.8-44.4c9.5-51.1,14.8-110.1,15.8-177.5c1.2-67.2,3.2-105.7,6.4-115.2c3.2-9.5,8.4-14.2,15.4-14.2c18.3,0.1,72,20.3,161.4,60.8c89.4,40.4,150.3,71.4,182.7,92.5c32.6,21.2,52.8,31.8,60.6,31.8c7.8,0,15.1-4.8,21.8-14.7c6.8-9.9,10.2-19.1,10.2-27.6c0.1-18.3-46.6-62.5-139.8-132.6l0.3-60.4c0.1-27.6-10.3-41.4-31.1-41.5c-18.3-0.1-27.5,9.8-27.5,29.6l-0.1,29.6l-83-63l0.2-41.4c0.2-39.6-10.2-59.4-31-59.5c-18.4-0.1-27.5,10.6-27.6,31.8l-0.1,25.1l-117.6-100L780,83.7C780.4-27,762.2-82.4,725.3-82.6c-14.5-0.1-26.6,22.2-36.1,66.7c-9.4,44.4-14.3,77.5-14.4,99.1L674.3,213L555.8,312z",
  "vessel-cargo":
    "M-288.9,407.2c0-0.3,0.1-0.6,0.3-0.8l5-4.4c1.5-1.3,3.8-1.3,5.3,0l5,4.4c0.2,0.2,0.3,0.5,0.3,0.8v31.5c0,1.1-0.9,2-2,2h-12c-1.1,0-2-0.9-2-2v-31.5H-288.9z",
  "vessel-fishing":
    "M-263.1,405.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.7,1-2.6,4.4-2.6,5.3v20c0,1.4,1.5,6.5,2.8,7.5c0.1,0.1,0.2,0.1,0.3,0.1c5.1,1.9,6.7,1.9,11.8,0c0.1,0,0.2-0.1,0.3-0.1c1.3-1,2.8-6.1,2.8-7.5v-20c0-0.8-2-4.2-2.6-5.3c-0.1-0.1-0.2-0.2-0.3-0.3l-5.4-3c-0.7-0.4-0.7-0.4-1.4,0L-263.1,405.2z",
  "vessel-sailing":
    "M-287.9,404.6c-0.1,0.1-0.1,0.2-0.1,0.3l-3,9.6v23.3c0,0.4,0.3,0.8,0.7,0.9l5,1.7c0.2,0.1,0.4,0.1,0.6,0l5-1.7c0.4-0.1,0.7-0.5,0.7-0.9v-23.3l-3-9.6c0-0.1-0.1-0.2-0.1-0.3l-2.1-2.9c-0.4-0.6-1.2-0.6-1.6,0L-287.9,404.6z",
  "vessel-passenger":
    "M-285.1,404.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.7,1.1-2.6,4.7-2.6,5.6l2,26.2c0,0.9,0.4,1.7,1.1,2l4.6,2.1c0.8,0.4,1.7,0.4,2.5,0l4.6-2.1c0.7-0.3,1.1-1.1,1.1-2l2-26.2c0-0.9-2-4.5-2.6-5.6c-0.1-0.1-0.2-0.2-0.3-0.3l-5.4-3.2c-0.7-0.4-0.7-0.4-1.4,0L-285.1,404.4z",
  "vessel-pleasure":
    "M-284.6,409.1c-1,2.3-1.7,3.9-2.4,7.5c-0.6,3.1-1,5.7-1.1,6.7c0,0.2,0.1,14.4,0.4,16.2c0,0.1,0.1,0.2,0.2,0.2l8.2,1c0.2,0,0.3,0,0.5,0l8.2-1c0.1,0,0.2-0.1,0.2-0.2c0.2-1.8,0.4-16,0.4-16.2c-0.1-1-0.5-3.6-1.1-6.7c-0.7-3.7-1.4-5.3-2.4-7.5c-0.1-0.2-0.2-0.5-0.3-0.7c-0.6-1.4-4.9-7.8-5.2-7.4c-0.3-0.4-4.6,6.1-5.2,7.4C-284.4,408.6-284.5,408.8-284.6,409.1z",
  "vessel-tanker":
    "M-290,411.8c0-0.9,0.3-1.8,0.7-2.5l5.6-8.1c0.4-0.5,1-0.5,1.4,0l5.6,8.1c0.5,0.7,0.7,1.6,0.7,2.5v24.9c0,0.8-0.4,1.6-1.1,1.9l-4.6,2c-0.8,0.4-1.7,0.4-2.5,0l-4.6-2c-0.7-0.3-1.1-1-1.1-1.9v-24.9H-290z",
  "vessel-other":
    "M-289,412c0-0.9,0.3-1.8,0.9-2.5l6.3-7.9c0.4-0.5,1.2-0.5,1.6,0l6.3,7.9c0.6,0.7,0.9,1.6,0.9,2.5v5.6v1v20c0,1.1-0.9,2-2,2h-12c-1.1,0-2-0.9-2-2v-20v-1L-289,412L-289,412z",
  wikiloc: WikilocIcon,
  offenders: OffendersIcon,
  meetup: MeetupIcon,
  nextdoor: NextdoorIcon,
  snmp: SnmpIcon,
  kasa: KasaIcon,
  bikemap: BikemapIcon,
  findpenguins: FindpenguinsIcon,
  protests: ProtestsIcon,
  events3: Events3Icon,
  events2: Events2Icon,
  events1: Events1Icon,
  photo_geolocation: PhotoGeolocationIcon,
  uploaded_items: UploadedIcon,
  crimes2: Crimes2Icon,
  events4: Events4Icon,
  booking: BookingIcon,
  events5: Events5Icon,
  tiktok: TiktokIcon,
  twitter: TwitterIcon,
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  telegram: TelegramIcon,
  trestle: TrestleIcon,
  wifi: WifiIcon,
  ai_satellite: SatelliteIcon,
  facebook_marketplace: FacebookMarketplaceIcon,
  facebook_events: FacebookEventsIcon,
  airports: AirportsIcon,
  stadium: StadiumIcon,
  stores: StoresIcon,
  disasters: DisastersIcon,
  weather: WeatherIcon,
};

export const OSM_FIELDS: OSMSearchObject[] = [
  {
    name: "airport",
    title: "airport",
  },
  {
    name: "airstrip",
    title: "airstrip",
  },
  {
    name: "ambulance_station",
    title: "ambulance station",
  },

  {
    name: "animal_shelter",
    title: "animal shelter",
  },
  {
    name: "aquarium",
    title: "aquarium",
  },
  {
    name: "aqueduct",
    title: "aqueduct",
  },
  {
    name: "archaeological_site",
    title: "archaeological site",
  },
  {
    name: "archive",
    title: "archive",
  },
  {
    name: "arts_centre",
    title: "arts centre",
  },
  {
    name: "atm",
    title: "atm",
  },
  {
    name: "bakery",
    title: "bakery",
  },
  {
    name: "bank",
    title: "bank",
  },
  {
    name: "baptist_church",
    title: "baptist church",
  },
  {
    name: "bar",
    title: "bar",
  },
  {
    name: "barbecue",
    title: "barbecue",
  },
  {
    name: "bay",
    title: "bay",
  },
  {
    name: "beach",
    title: "beach",
  },
  {
    name: "beach_resort",
    title: "beach resort",
  },
  {
    name: "bell_tower",
    title: "bell tower",
  },
  {
    name: "bench",
    title: "bench",
  },
  {
    name: "bicycle_parking",
    title: "bicycle parking",
  },
  {
    name: "boat_rental",
    title: "boat rental",
  },
  {
    name: "brewery",
    title: "brewery",
  },
  {
    name: "bridge",
    title: "bridge",
  },
  {
    name: "bus_station",
    title: "bus station",
  },
  {
    name: "bus_stop",
    title: "bus stop",
  },
  {
    name: "cafe",
    title: "cafe",
  },
  {
    name: "camp_site",
    title: "camp site",
  },
  {
    name: "canal",
    title: "canal",
  },
  {
    name: "car_wash",
    title: "car wash",
  },
  {
    name: "casino",
    title: "casino",
  },
  {
    name: "castle",
    title: "castle",
  },
  {
    name: "cathedral",
    title: "cathedral",
  },
  {
    name: "cave",
    title: "cave",
  },
  {
    name: "cemetery",
    title: "cemetery",
  },
  {
    name: "charging_station",
    title: "charging station",
  },
  {
    name: "childcare",
    title: "childcare",
  },
  {
    name: "church",
    title: "church",
  },
  {
    name: "cinema",
    title: "cinema",
  },
  {
    name: "club",
    title: "club",
  },
  {
    name: "college",
    title: "college",
  },
  {
    name: "communications_tower",
    title: "communications tower",
  },
  {
    name: "community_centre",
    title: "community centre",
  },
  {
    name: "construction",
    title: "construction",
  },
  {
    name: "convenience_store",
    title: "convenience store",
  },
  {
    name: "courthouse",
    title: "courthouse",
  },
  {
    name: "coworking_space",
    title: "coworking space",
  },
  {
    name: "crossing",
    title: "crossing",
  },
  {
    name: "cycleway",
    title: "cycleway",
  },
  {
    name: "dam",
    title: "dam",
  },
  {
    name: "dentist",
    title: "dentist",
  },
  {
    name: "department_store",
    title: "department store",
  },
  {
    name: "dock",
    title: "dock",
  },
  {
    name: "dog_park",
    title: "dog park",
  },
  {
    name: "embassy",
    title: "embassy",
  },
  {
    name: "emergency_access_point",
    title: "emergency access point",
  },
  {
    name: "energy_supply",
    title: "energy supply",
  },
  {
    name: "factory",
    title: "factory",
  },
  {
    name: "farm",
    title: "farm",
  },
  {
    name: "fire_station",
    title: "fire station",
  },
  {
    name: "fishing",
    title: "fishing",
  },
  {
    name: "fitness_centre",
    title: "fitness centre",
  },
  {
    name: "fort",
    title: "fort",
  },
  {
    name: "fountain",
    title: "fountain",
  },
  {
    name: "fuel",
    title: "fuel",
  },
  {
    name: "garden",
    title: "garden",
  },
  {
    name: "generator",
    title: "generator",
  },
  {
    name: "golf_course",
    title: "golf course",
  },
  {
    name: "grave_yard",
    title: "grave yard",
  },
  {
    name: "gym",
    title: "gym",
  },
  {
    name: "hacker_space",
    title: "hacker space",
  },
  {
    name: "harbor",
    title: "harbor",
  },
  {
    name: "health_centre",
    title: "health centre",
  },
  {
    name: "helicopter_pad",
    title: "helicopter pad",
  },
  {
    name: "hospital",
    title: "hospital",
  },
  {
    name: "hotel",
    title: "hotel",
  },
  {
    name: "ice_cream",
    title: "ice cream",
  },
  {
    name: "jewelry",
    title: "jewelry",
  },
  {
    name: "karaoke",
    title: "karaoke",
  },
  {
    name: "kindergarten",
    title: "kindergarten",
  },
  {
    name: "kiosk",
    title: "kiosk",
  },
  {
    name: "lake",
    title: "lake",
  },
  {
    name: "library",
    title: "library",
  },
  {
    name: "lifeguard_tower",
    title: "lifeguard tower",
  },
  {
    name: "lighthouse",
    title: "lighthouse",
  },
  {
    name: "marina",
    title: "marina",
  },
  {
    name: "market",
    title: "market",
  },
  {
    name: "marketplace",
    title: "marketplace",
  },
  {
    name: "massage",
    title: "massage",
  },
  {
    name: "memorial",
    title: "memorial",
  },
  {
    name: "military",
    title: "military",
  },
  {
    name: "mini_roundabout",
    title: "mini roundabout",
  },
  {
    name: "monument",
    title: "monument",
  },
  {
    name: "motel",
    title: "motel",
  },
  {
    name: "museum",
    title: "museum",
  },
  {
    name: "nightclub",
    title: "nightclub",
  },
  {
    name: "observatory",
    title: "observatory",
  },
  {
    name: "optician",
    title: "optician",
  },
  {
    name: "park",
    title: "park",
  },
  {
    name: "parking",
    title: "parking",
  },
  {
    name: "parking_space",
    title: "parking space",
  },
  {
    name: "pharmacy",
    title: "pharmacy",
  },
  {
    name: "pier",
    title: "pier",
  },
  {
    name: "pipeline",
    title: "pipeline",
  },
  {
    name: "place_of_worship",
    title: "place of worship",
  },
  {
    name: "planetarium",
    title: "planetarium",
  },
  {
    name: "playground",
    title: "playground",
  },
  {
    name: "police",
    title: "police",
  },
  {
    name: "port",
    title: "port",
  },
  {
    name: "post_box",
    title: "post box",
  },
  {
    name: "post_office",
    title: "post office",
  },
  {
    name: "power_pylon",
    title: "power pylon",
  },
  {
    name: "power_station",
    title: "power station",
  },
  {
    name: "prison",
    title: "prison",
  },
  {
    name: "pub",
    title: "pub",
  },
  {
    name: "public_bath",
    title: "public bath",
  },
  {
    name: "public_bookcase",
    title: "public bookcase",
  },
  {
    name: "railway_yard",
    title: "railway yard",
  },
  {
    name: "ranger_station",
    title: "ranger station",
  },
  {
    name: "research_institute",
    title: "research institute",
  },
  {
    name: "rest_area",
    title: "rest area",
  },
  {
    name: "restaurant",
    title: "restaurant",
  },
  {
    name: "retirement_home",
    title: "retirement home",
  },
  {
    name: "river",
    title: "river",
  },
  {
    name: "river_bank",
    title: "river bank",
  },
  {
    name: "sauna",
    title: "sauna",
  },
  {
    name: "school",
    title: "school",
  },
  {
    name: "shelter",
    title: "shelter",
  },
  {
    name: "shooting_range",
    title: "shooting range",
  },
  {
    name: "spa",
    title: "spa",
  },
  {
    name: "speed_camera",
    title: "speed camera",
  },
  {
    name: "sports_centre",
    title: "sports centre",
  },
  {
    name: "stadium",
    title: "stadium",
  },
  {
    name: "street_lamp",
    title: "street lamp",
  },
  {
    name: "substation",
    title: "substation",
  },
  {
    name: "subway_station",
    title: "subway station",
  },
  {
    name: "summer_camp",
    title: "summer camp",
  },
  {
    name: "supermarket",
    title: "supermarket",
  },
  {
    name: "swimming_pool",
    title: "swimming pool",
  },
  {
    name: "table_tennis",
    title: "table tennis",
  },
  {
    name: "taxi",
    title: "taxi",
  },
  {
    name: "tennis_court",
    title: "tennis court",
  },
  {
    name: "theatre",
    title: "theatre",
  },
  {
    name: "theme_park",
    title: "theme park",
  },
  {
    name: "ticket_booth",
    title: "ticket booth",
  },
  {
    name: "toilets",
    title: "toilets",
  },
  {
    name: "tourist_info",
    title: "tourist info",
  },
  {
    name: "tower",
    title: "tower",
  },
  {
    name: "traffic_signals",
    title: "traffic signals",
  },
  {
    name: "trailer_park",
    title: "trailer park",
  },
  {
    name: "train_station",
    title: "train station",
  },
  {
    name: "tram_stop",
    title: "tram stop",
  },
  {
    name: "tunnel",
    title: "tunnel",
  },
  {
    name: "turning_circle",
    title: "turning circle",
  },
  {
    name: "university",
    title: "university",
  },
  {
    name: "veterinary",
    title: "veterinary",
  },
  {
    name: "viewpoint",
    title: "viewpoint",
  },
  {
    name: "vineyard",
    title: "vineyard",
  },
  {
    name: "volcano",
    title: "volcano",
  },
  {
    name: "waste_basket",
    title: "waste basket",
  },
  {
    name: "wastewater_plant",
    title: "wastewater plant",
  },
  {
    name: "water_body",
    title: "water body",
  },
  {
    name: "water_tower",
    title: "water tower",
  },
  {
    name: "water_works",
    title: "water works",
  },
  {
    name: "waterfall",
    title: "waterfall",
  },
  {
    name: "watering_place",
    title: "watering place",
  },
  {
    name: "watermill",
    title: "watermill",
  },
  {
    name: "windmill",
    title: "windmill",
  },
  {
    name: "zoo",
    title: "zoo",
  },
  {
    name: "wind_turbine",
    title: "wind turbine",
  },
];
